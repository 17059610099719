<template>
  <div class="px-5 py-2">
      <!-- LOADER -->
      <div class="grid grid-cols-1 md:grid-cols-12 grid-flow-col gap-10 gap-y-5" v-if="loading">
        <div class="col-start-1 col-end-1 md:col-start-1 md:col-end-8">
           <v-skeleton :type="'list-item-avatar, divider, list-item-three-line, list-item-three-line'" :width="'100%'" />
        </div>
        <div class="col-start-1 col-end-1 md:col-start-8 md:col-end-13">
           <v-skeleton :type="'list-item-avatar, divider, list-item-three-line, list-item-three-line'" :width="'100%'" />
        </div>
      </div>

      <!-- FORMS -->
      <div v-else class="grid grid-cols-1 md:grid-cols-12 grid-flow-col gap-10 gap-y-5">
          <div class="col-start-1 col-end-1 md:col-start-1 md:col-end-8 border border-gray-200 mt-2 shadow-sm rounded-md">
          <div class="h-10 flex items-center gap-2 text-gray-500 font-bold bg-gray-100 px-5 py-1 font-sans tracking-wide rounded-tr-md rounded-tl-md border-b border-gray-200">
            <f-icon :icon="['fas', 'info-circle']" />
              <small>VISIBILIDAD</small>
          </div>
          <div>
              <v-sheet class="px-5">
                <div class="flex items-center gap-5" v-for="(config, i) in configuration" :key="i">
                    <v-switch
                    v-model="config.activo"
                    color="success"
                    inset
                    :label="`${config.name ? config.name : ''}`"
                    @change="onChangeConfig(config.id)"
                    ></v-switch>
                </div>
              </v-sheet>
          </div>
        </div>
        <div class="col-start-1 col-end-1 md:col-start-8 md:col-end-13 border border-gray-200 mt-2 shadow-sm rounded-md">
           <div class="h-10 flex items-center gap-2 text-gray-500 font-bold bg-gray-100 px-5 py-1 font-sans tracking-wide rounded-tr-md rounded-tl-md border-b border-gray-200">
              <f-icon :icon="['fas', 'dollar-sign']" />
              <small>IMPUESTOS</small>
          </div>
           <div>
              <v-sheet class="px-5">
                <div class="flex items-center gap-5" v-for="(tax, i) in taxes" :key="i">
                    <v-switch
                    v-if="!tax.isedit"
                    v-model="tax.activo"
                    color="success"
                    inset
                    :label="`${tax.code ? tax.code + '(' + tax.value + '%)' : ''}`"
                    @change="onChangeTax(tax)"
                    ></v-switch>
                    <div class="flex items-center gap-5 mt-4" v-if="tax.isedit">
                      <div class="grid">
                        <input v-model="$v.tax_edit.value.$model" class="input-full-fucsia"  placeholder="Ingrese el valor" />
                        <small class="text-error" v-show="$v.tax_edit.value.$anyDirty && !$v.tax_edit.value.required">* El valor es requerido</small>
                      </div>
                      <div>
                        <svg @click="onSaveTaxEdit(tax)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <div>
                        <svg @click="onCloseEditTax(tax)" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </div>
                    </div>
                    <svg v-show="!tax.isedit" @click="onEditTax(tax)" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                    </svg>
                </div>
                
              </v-sheet>
          </div>
           
        </div>

      </div>
  </div>
</template>

<script>
import ToastMixin from '@/mixins/vue/toastmixin';
import entityConfigService from '../../services/entity/entityconfig.service';
import EntityConfig from '../../models/entity/entityconfig.model';
import entityTaxService from '../../services/entity/entitytax.service';
import EntityTax from '../../models/entity/entitytax.model';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
  mixins: [ToastMixin, validationMixin],
  props: {
    id_entity: {
      type: String,
      default: ""
    }
  },

  data: function() {
    return {
      loading: false,
      configuration: [],
      taxes: [],
      idtaxselected: '',
      istaxedit: false,
      tax_edit: new EntityTax()
    }
  },
  validations: {
     tax_edit: {
       value: {
         required
       }
     }
  },
  mounted() {
    this.init();
  },
  methods: {
    init: async function() {
      this.loading = true;
      if(this.id_entity != null && this.id_entity != '') {
        await this.getConfiguration(this.id_entity);
        await this.getEntityTaxs(this.id_entity);
      }
         this.loading = false;
    },
    getConfiguration: async function(id) {
      let response = await entityConfigService.getByIdEntity(id);
      if(response.success) {
        this.configuration = response.data;
      }else {
        this.failure(response.message);
      }
    },
    getEntityTaxs: async function(id) {
      let response = await entityTaxService.getAll(id);
      if(response.success) {
        this.taxes = response.data.map(item => ({...item, isedit: false}));
      }else {
        this.failure(response.message);
      }
    },
    onChangeConfig: async function(id) {
      let entityConf = new EntityConfig();
      let config = this.configuration.find(x => x.id == id);
      if(config) {
        entityConf.id_entity = this.id_entity;
        entityConf.id_cattype_information = config.id;
        entityConf.active = config.activo;

        let response = await entityConfigService.save(entityConf);
        if(response.success) {
          this.init();
        }else {
           this.failure(response.message);
        }
      }
    },
    onChangeTax: async function(data) {
      let tax = new EntityTax();
      if(data) {
        tax.id_entity = this.id_entity;
        tax.id_tax = data.id_tax;
        tax.active = data.activo;
        tax.value = data.value;
        tax.id_c_tax = data.id;

        let response = await entityTaxService.setTax(this.id_entity, tax);
        if(response.success) {
          this.init();
        }else {
           this.failure(response.message);
        }
      }
    },
    onEditTax: function(item) {
      this.tax_edit.value = item.value;
      item.isedit = true;
    },
    onCloseEditTax: function(item) {
      this.tax_edit.value = null;
      item.isedit = false;
    },
    onSaveTaxEdit: async function(item) {
      let tax = new EntityTax();

      tax.id_tax = item.id;
      tax.id_entity = this.id_entity;
      tax.active = item.activo;
      tax.value = this.tax_edit.value;

      this.$v.$touch();
      if(!this.$v.$anyError) {
        console.log(tax);
        let response = await entityTaxService.setTax(this.id_entity, tax);
        if(response.success) {
          this.init();
        }else {
           this.failure(response.message);
        }

      }

    }

  },
  watch: {
    id_entity: function() {
      if(this.id_entity) {
         this.init();
      }
    },
    isconfiguration: function() {
        this.$emit('disableconfig', this.isconfiguration);
    }
  },
  computed: {
    isconfiguration: function() {
          let conf = this.configuration.find(x => x.activo);
          return conf ? true : false;
    }
  }
}
</script>

<style>

</style>