<template>
  <div class="px-5 py-2 ">
      <v-loading :is_loading="isloading" />
      <div class=" grid grid-cols-1 md:grid-cols-12 grid-flow-col gap-10 gap-y-5">
      <div class="block gap-y-3 col-start-1 col-end-1 md:col-start-1 md:col-end-8 border border-gray-200 mt-2 shadow-sm rounded-md">
          <div class="h-10 flex items-center gap-2 text-gray-500 font-bold bg-gray-100 px-5 py-2 font-sans tracking-wide rounded-tr-md rounded-tl-md border-b border-gray-200">
               <f-icon :icon="['fas', 'info-circle']" />
              <small>INFORMACIÓN</small>
          </div>
          <div class="block py-3">
            <div class="grid grid-cols-12 px-3">
                <div class="col-span-1 text-gray-500 md:flex md:justify-end md:px-3">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div class="col-span-11 text-justify font-sans text-gray-700">
                    <p>{{entity.description ? entity.description : ''}}</p>
                </div>
            </div>
            <div class="grid grid-cols-12 items-center px-3">
                <div class="col-span-1 text-gray-500 md:flex md:justify-end md:px-3">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>
                </div>
                <div class="col-span-11 text-justify font-sans text-gray-700 text-lg">
                    <span>{{entity.telephone | phone}}</span>
                </div>
            </div>
            <div class="grid grid-cols-12 items-center px-3">
                <div class="col-span-1 md:flex md:justify-end md:px-3 text-gray-500">
                    <v-icon>mdi-whatsapp</v-icon>
                </div>
                <div class="col-span-11 text-justify font-sans text-gray-700 text-lg">
                    <span>{{entity.whatsapp | phone}}</span>
                </div>
            </div>
            <div class="grid grid-cols-12 items-center px-3">
                <div class="col-span-1 md:flex md:justify-end md:px-3 text-gray-500">
                    <v-icon>mdi-web</v-icon>
                </div>
                <div class="col-span-11 text-justify font-sans text-gray-700 text-lg">
                    <a :href="entity.web" target="_blank">
                        <span>{{entity.web ? entity.web : ''}}</span>
                    </a>
                </div>
            </div>
            <div class="grid grid-cols-12 items-center px-3">
                <div class="col-span-1 md:flex md:justify-end md:px-3 text-gray-500">
                    <v-icon>mdi-eye-outline</v-icon>
                </div>
                <div class="col-span-11 text-justify font-sans  text-lg">
                        <v-chip
                        class="ma-2"
                        :color="!entity.active ? 'red' : 'success'"
                        text-color="white"
                        label
                        >
                        {{!entity.active ? 'NO DISPONIBLE' : 'DISPONIBLE'}}
                        </v-chip>
                </div>
            </div>
            <div class="grid grid-cols-12 items-center px-3">
                <div class="col-span-1 md:flex md:justify-end md:px-3 text-gray-500">
                    <v-icon>mdi-calendar</v-icon>
                </div>
                <div class="col-span-11 text-justify font-sans text-gray-700 text-lg">
                    <span>{{entity.created_at | formatDate }}</span>
                </div>
            </div>
            <div class="flex justify-between md:justify-end gap-5 items-center px-10 py-3">
                <div>
                    <a :href="entity.facebook" target="_blank">
                    <v-icon size="45">mdi-facebook</v-icon> 
                    </a>
                </div>
                <div>
                    <a :href="entity.twitter" target="_blank">
                    <v-icon size="45">mdi-twitter</v-icon>
                    </a>
                </div>
                <div>
                    <a :href="entity.instagram" target="_blank">
                    <v-icon  size="45">mdi-instagram</v-icon>
                    </a>
                </div>
            </div>
          </div>
      </div>
       <div class="col-start-1 col-end-1 md:col-start-8 md:col-end-13 border border-gray-200 mt-2 shadow-sm rounded-md">
           <div class="h-10 flex items-center gap-2 text-gray-500 font-bold bg-gray-100 px-5 py-2 font-sans tracking-wide rounded-tr-md rounded-tl-md border-b border-gray-200">
               <f-icon :icon="['fas', 'bookmark']" />
              <small>ETIQUETAS</small>
          </div>
            <div >
             <div class="mt-5 grid md:flex">
                <v-col>
                <v-card-text>
                    <v-treeview
                    v-model="tagsselected"
                    :items="tags"
                    item-disabled="locked"
                    selected-color="pink darken-1"
                    open-on-click
                    selectable
                    return-object
                    expand-icon="mdi-chevron-down"
                    on-icon="mdi-bookmark"
                    off-icon="mdi-bookmark-outline"
                    indeterminate-icon="mdi-bookmark-minus"
                    >
                    </v-treeview>
                </v-card-text>
            </v-col>
            <div class="hidden md:block">
              <v-divider vertical ></v-divider>
            </div>
              <v-col>
              <v-card-text class="h-full">
                <div v-if="!tagsselected || tagsselected.length <= 0" key="title" class="flex justify-center h-full items-center text-sm font-thin text-gray-400 text-center">
                    Selecciona una o mas etiquetas relacionadas a tu entidad
                </div>
                 <v-scroll-x-transition
                    group
                    hide-on-leave
                 >
                <v-chip
                    v-for="(selection, i) in tagsselected"
                    :key="i"
                    color="pink darken-1"
                    dark
                    small
                    close
                    class="ma-1"
                     @click:close="onRemoveTag(selection)"
                    >
                    <v-icon
                        left
                        small
                    >
                        mdi-tag
                    </v-icon>
                    {{ selection.name }}
                 </v-chip>

                 </v-scroll-x-transition>
              </v-card-text>
               
               </v-col>
               
            </div>
             <div class="px-2 py-2 block md:grid justify-end" v-if="ischangetags">
                    
                     <button :disabled="isloading"
                                class="flex  items-center w-full md:w-min justify-center gap-2 bg-fucsia-lighter text-white text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                      @click="savetags"
                      v-security="{acm: acm.ENTITYINFO.name, acc: acc.ADD_TAGS}"
                     >
                        Guardar
                            <v-icon
                            left
                            small
                            color="white"
                        >
                            mdi-content-save
                        </v-icon>   
                    </button>
                    
                </div>
               
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import SecurityMixin from '@/mixins/security/RBSecurityMixin';
import ToastMixin from '@/mixins/vue/toastmixin';
import GlobalMixin from '@/mixins/globalMixin';
import { EntityService } from '../../services/HSuite/Context/hsuite-rebyu-context'
import tagsService from '../../services/catalogs/tags.service';
import EntityTags from '../../models/entity/entitytags.model';


export default {
    mixins: [ToastMixin, GlobalMixin, SecurityMixin],
    props: {
        id_entity: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            isloading: false,
            iscreate: false,
            ischangetags: false,
            entity: {},
            tags: [],
            tagsselected: []
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function() {
            this.isloading = true;
            await this.getEntity(this.id_entity);
            await this.getTags();
            await this.getTagsEntity(this.id_entity);
            this.isloading = false;
        }, 
        getEntity: async function(id) {
            let response = await EntityService.getInfo(id);
            if(response.success) {
                this.entity = response.data;
            }else {
                this.failure(response.message);
            }
        },
        getTagsEntity: async function(id) {
            let response = await EntityService.getTagsByEntity(id);
            if(response.success) {
              let tags_entity = response.data;
              let maptags = tags_entity.map((t) => {return {id: t.idCatTag, name: t.name}}  ) ;
              this.tagsselected = maptags;
            }
        },
        getTags: async function() {
            let response = await tagsService.getAll()
            if(response.success) {
                this.tags = response.data;
            }
        },
        onRemoveTag: function(item) {
            const index = this.tagsselected.indexOf(item);
           if(index && index != -1) {
               this.tagsselected.splice(index, 1);
           }
           if(index == 0) {
               this.tagsselected.shift();
           }
        },
         savetags: async function() {
              
              const Entity_Tags = new EntityTags();

              const tags = [...new Set(this.tagsselected.map((e) => e.id))]

              Entity_Tags.listTags =  tags;
              Entity_Tags.idEntity = this.id_entity;

              this.isloading = true;
              let response = await EntityService.saveTags(Entity_Tags);
              this.isloading = false;
              if(response.success) {
                  this.success(response.message);
                  this.ischangetags = false;
                  this.init();
              }else {
                this.ischangetags = false;
                  this.failure(response.message);
              }
      },
       onsuccess: function() {
        this.iscreate = false;
        this.init();
        }
    },
    
    watch:  {
        tagsselected: function() {
             this.ischangetags = true;
        }
    }
}
</script>

<style>

</style>