export default class EntityConfig {
    constructor(
        id_entity,
        id_cattype_information,
        active
    ) {
        this.id_entity = id_entity ? id_entity : '',
        this.id_cattype_information = id_cattype_information ? id_cattype_information : '',
        this.active = active ? active : false
    }
}