export default class EntityTax {
    constructor(
        id_tax,
        id_entity,
        active,
        value
    ) {
        this.id_tax = id_tax ? id_tax : '',
        this.id_entity = id_entity ? id_entity : '',
        this.active = active ? active : false;
        this.value = value ? value : '';
    }
}