<template>
  <v-card>
       <v-loading :is_loading="isloading" />
       <v-modal-title v-on:close="$emit('close')">
            Nueva Categoría
       </v-modal-title>
       <div class="py-2 sm:px-0 md:px-5">
           <div class="grid grid-cols-1 gap-y-2 gap-10 px-3 md:px-5 py-2">
            <div>
                    <label class="text-base">Nombre</label>
                    <input :disabled="isloading" v-model="$v.category.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                    <small class="text-error" v-show="$v.category.name.$anyDirty && !$v.category.name.required">* El nombre es requerido</small>
            </div>
           </div>
           <div class="grid grid-cols-1 gap-y-2 gap-10 px-3 md:px-5 py-2">
               <div>
                    <label class="text-base">Imagen</label>
                    <cropper
                      ref="cropper"
                        class="cropper mt-2"
                        :src="image"
                        :transitions="true"
                        image-restriction="fit-area"
                        :min-height="500"
                        :min-width="500"
                    >
                    </cropper>
               </div>
               <small class="text-error" v-show="$v.images.categoryPictureFile.$anyDirty && !$v.images.categoryPictureFile.required">* la imagen es requerida</small>
                <div class="mt-2 flex gap-5 justify-center items-center">
                <v-btn
                    color="pink"
                    dark
                    fab
                    @click="$refs.file.click()"
                    >
                    <input ref="file" type="file" class="hidden" accept="image/*" @change="uploadImage($event)" />
                    <v-icon>mdi-cloud-upload</v-icon>
                </v-btn>
                    <v-btn
                    color="red"
                    dark
                    fab
                    @click="removeFile"
                    >
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </div>
           </div>

       </div>
        <v-card-actions >
          <v-spacer class="hidden md:block" ></v-spacer>
          <div class="grid w-full md:w-min gap-y-2 gap-2 md:flex">
            <div class="w-full">
                <button :disabled="isloading"
                    class="w-full bg-gray-200 text-gray-500 text-lg tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none" 
                    @click="$emit('close')">Cancelar</button>
            </div>
            <div class="w-full">
                <button :disabled="isloading"
                    class="w-full bg-fucsia-lighter text-white text-lg tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                @click="save" >Guardar</button>
            </div>
          </div>
        </v-card-actions>
  </v-card>
</template>

<script>
import toastMixin from "@/mixins/vue/toastmixin";
import Category from '../../models/category/category.model';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import categoryService from '../../services/entity/category.service';
import file from '../../utils/file/file';
import cloudStorageService from '../../services/firebase/cloudstorage.service';
import commoncontext from '../../utils/common/firebasecontext';
import uuid from 'uuid';

export default {
    mixins: [toastMixin, validationMixin],
    props: {
        open: {
            type: Boolean,
            default: false
        },
        id_entity: {
            type: String,
            default: ""
        },
        id_type: {
            type: String,
            default: ""
        },
    },
    data: function() {
        return {
            isloading: false,
            category: new Category(),
            image: '',
            images: {
                categoryPictureFile: ''
            }
        }
    },
    validations: {
         category: {
             name: {
                 required
             },
           
             id_type: {
                 required
             },
             idEntity: {
                 required
             },
             public_id: {
                 required
             },
             fullpath: {
                 required
             }
         },
         images: {
             categoryPictureFile: {
                 required
             },
         }
    },
    methods: {
        save: async function() {
             if(this.$refs.cropper.getResult().canvas) {
                 this.isloading = true;
                 this.images.categoryPictureFile = this.$refs.cropper.getResult().canvas.toDataURL();
                
                 // SUBIR IMAGEN AL CLOUD STORAGE
                 const ID_FIREBASE = uuid.v4().toString();
                let blob = await file.b64toBlob(this.images.categoryPictureFile, 'image/jpeg');
                const responseCloud = await cloudStorageService.uploadFile(commoncontext.CATEGORY, blob, ID_FIREBASE);
                 this.category.image = responseCloud.url;
                 this.category.public_id = responseCloud.public_id;
                 this.category.fullpath = responseCloud.fullPath;


                 this.category.id_type = this.id_type;
                 this.category.idEntity = this.id_entity;
                 this.isloading = false;
                 
                 this.$v.$touch();
                 if(!this.$v.$anyError) {
                     this.isloading = true;
                     let response = await categoryService.save(this.category);
                     this.isloading = false;
                     if(response.success) {
                         this.success(response.message);
                         this.$emit('success');
                     }else {
                          this.failure(response.message);
                     }
                  }

             }else {
                  this.failure('Seleccione una imagen');
             }
        },
        uploadImage(event) {
			// Reference to the DOM input element
			var input = event.target;
			// Ensure that you have a file before attempting to read it
			if (input.files && input.files[0]) {
				// create a new FileReader to read this image and convert to base64 format
				var reader = new FileReader();
				// Define a callback function to run, when FileReader finishes its job
				reader.onload = (e) => {
					// Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
					// Read image as base64 and set to imageData
					this.image = e.target.result;
				};
				// Start the reader job - read file as a data url (base64 format)
				reader.readAsDataURL(input.files[0]);
			}
		},
        removeFile() {
              this.image = null;
             this.$refs.file.value = '';
       }
    },
    watch: {
        open: function() {
            if(this.open) {
                this.$v.$reset();
                this.category = new Category();
            }
        }
    }

}
</script>

<style scoped>
.cropper {
	height: 300px;
	background: #DDD;
}
</style>