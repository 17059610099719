import ApiProducts from '../config/ApiProducts';
import api_url from '../../utils/api.url';

export default  {

    /**
     * Función que se encarga de obtener los impuestos de la entidad
     * @param {any} data Modelo de datos de la entidad
     * @returns 
     */
    async getAll(id_entity) {
        try {
            const url = api_url.APP_PRODUCTS_ENTITY_TAX_GET_ALL + id_entity;
            return await ApiProducts.get(url)
        } catch (error) {
            return error.response.data;
        }
    }, 
    
    /**
     * Función que permite activar o desactivar los impuestos en una entidad
     * @param {String} id_entity Identificador de la entidad
     * @param {*} data Modelo de datos del impuesto
     * @returns 
     */
    async setTax(id_entity, data) {
        try {
            const url = api_url.APP_PRODUCTS_ENTITY_TAX_SET + id_entity;
            return await ApiProducts.put(url, data)
        } catch (error) {
            return error.response.data;
        }
    }, 
    

}