<template>
  <div class="px-5 py-2">
      <v-loading :is_loading="isloading" />
      <div class="grid gap-y-3">
          <div v-for="(config, i) in configuration" :key="i">
            <div class="grid gap-y-5" v-if="config.activo" >
                <div class="grid gap-y-2">
                    <div class="flex justify-between items-center">
                        <div class="flex gap-2 items-center text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" />
                            </svg>
                            <label class="font-sans font-bold tracking-wide text-gray-500 text-2xl">{{config.name ? config.name : ''}}</label>
                        </div>
                        <div>
                             <v-dialog 
                                v-model="iscreate"
                                persistent
                                max-width="500px"
                                :retain-focus="false"
                                >
                                <template  v-slot:activator="{ on, attrs }" >
                                <v-btn
                                   v-bind="attrs" v-on="on"
                                    color="pink"
                                    dark
                                    fab
                                    small
                                    @click="id_conf_selected = config.id"
                                    >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                </template>
                                <v-category-create :id_entity="id_entity" :id_type="id_conf_selected"  :open="iscreate" v-on:close="iscreate = false" v-on:success="onsuccess" />
                             </v-dialog>
                        </div>
                    </div>
                    <v-divider></v-divider>
                </div>
                <div class="flex flex-wrap gap-10">
                        <div :class="{'bg-gray-600': !category.active, 'bg-gray-300 hover:bg-gray-50': category.active}" class="h-40 w-full md:w-60 rounded-lg px-5 py-2 shadow-md bg-cover bg-center grid bg-blend-multiply  transition duration-150 ease-in-out" :style="`background-image: url('${category.image}')`"  v-for="(category, i) in config.categories" :key="i">
                              <small v-if="category.active" class="font-sans tracking-wide text-gray-200 font-semibold">CATEGORIA</small>
                               <div v-if="!category.active">
                                   <div  class="text-xs tracking-wider text-white bg-gray-400 px-1 rounded-sm w-max">
                                    <span>NO VISIBLE</span>
                                   </div>
                                </div>
                            <div class="text-left">
                                <label class="text-white font-sans tracking-wide font-bold text-2xl overflow-ellipsis break-all">{{category.name ? category.name : ''}}</label>
                            </div>
                            <div class="flex justify-between gap-5">
                                <router-link :to="'/entity/products/admin/'+ category.id+'/'+id_entity" class="w-full">
                                <button class="w-full px-5 bg-white rounded-md shadow-md text-fucsia-lighter font-sans text-sm font-semibold py-1 hover:shadow-lg">Explorar</button>
                                </router-link>
                                 <v-category-btn-actions v-on:event="(event) => onEvent(event, category)" :category="category" />
                            </div>
                        </div>
                    <div v-if="!config.categories || config.categories.length <= 0" class="font-sans text-center text-md w-full text-gray-400 tracking-wider">
                        <span>Agrega una o mas categorias a tu {{config.name ? config.name : ''}}</span>
                    </div>
                </div>
            </div>
          </div>
          <div v-if="!configuration || configuration.length <=0 || !isconfiguration" class="px-5 py-5 text-center text-gray-400 font-sans font-medium">
              <span>Aún no ah seleccionado los datos a mostrar en su tienda, ir a CONFIGURACIÓN</span>
          </div>
      </div>

      <v-dialog 
        v-model="isupdate"
        persistent
        max-width="500px"
        :retain-focus="false"
        >
        <v-category-update :id_entity="id_entity" :id="id_category_selected"  :open="isupdate" v-on:close="isupdate = false" v-on:success="onupdate" />
      </v-dialog>
  </div>
</template>

<script>
import ToastMixin from '@/mixins/vue/toastmixin';
import GlobalMixin from '@/mixins/globalMixin';
import entityConfigService from '../../services/entity/entityconfig.service';
import categorycreate from '../category/create.vue';
import categoryupdate from '../category/update.vue';
import vcategorybtn from '../category/vcategorybtn.vue';
import actions from '../../utils/common/actions';
import categoryService from '../../services/entity/category.service';


export default {
    mixins: [ToastMixin, GlobalMixin],
    components: {
        'v-category-create' : categorycreate,
        'v-category-update' : categoryupdate,
        'v-category-btn-actions' : vcategorybtn
    },
    props: {
        id_entity: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            isloading: false,
            iscreate: false,
            isupdate: false,
            id_conf_selected: null,
            id_category_selected: null,
            configuration: []
        }
    },
    mounted() {
    this.init();
  },
  methods : {
      init: async function() {
     this.isloading = true;
     
      if(this.id_entity != null && this.id_entity != '') {
          await this.getConfiguration(this.id_entity);
      }
     this.isloading = false;
    },
    getConfiguration: async function(id) {
        try {
            let response = await entityConfigService.getByIdEntity(id);
            if(response && response.success) {
              this.configuration = response.data.filter(x => x.code != 'SYST_INFORMATION' && x.code != 'SYST_REBYU');
            }else {
              this.failure(response.message);
            }
        } catch (error) {
            this.isloading = false;
        }
    },
    onsuccess: function() {
        this.iscreate = false;
        this.init();
    },
    onupdate: function() {
        this.isupdate = false;
        this.init();
    },
    onEvent: function (event, item) {
              switch(event) {
                case actions.DISABLED:
                    this.onEnabledCategory(item.id, false);
                break;
                case actions.ENABLED:
                    this.onEnabledCategory(item.id, true);
                break;
                 case actions.EDIT:
                    this.isupdate = true;
                    this.id_category_selected = item.id;
                break;
            }
    },
    onEnabledCategory: async function (id, status) {
        let confirm = await this.confirm(`${!status ? 'Ocultar Categoría' : 'Mostrar Categoría'}`, `${!status ? '¿Deseas ocultar la categoría a los usuarios?, esta acción se puede revertir' : '¿Desea hacer disponible la categoría a los usuarios?'}` );
        if(confirm) {
            let data = {
                id_entity: this.id_entity,
                active: status,
            }
            this.isloading = true;
            let response = await categoryService.status(id, data);
            this.isloading = false;
            if(response.success) {
                this.success(response.message);
                this.init();
            }else {
                this.failure(response.message);
            }
        }

    }
  },
  computed: {
      isconfiguration: function() {
          let conf = this.configuration.find(x => x.activo);
          return conf ? true : false;
      }
  },
 


}
</script>

<style>

</style>