import ApiRebyu from '../config/ApiRebyu';
import api_url from '../../utils/api.url';

export default  {

    /**
     * Función que permite obtener la configuracion de las entidades
     * @param {String} id identificado de la Entidad
     * @returns 
     */
     async getByIdEntity(id) {
        try {
            const url = api_url.APP_REBYU_ENTITY_CONFIG_GET + id;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Funciónn que permite guardar los datos de confuguracion de las entidades
     * @param {*} data Modelo de datos de la configuracion
     * @returns 
     */
     async save(data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_CONFIG_SAVE;
            return await ApiRebyu.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },
}