import ApiRebyu from "../config/ApiRebyu";
import api_url from '../../utils/api.url';

export default  {

     /**
     * Funcion que permite obtener todos los tags de los catalogos
     * @returns 
     */
      async getAll() {
        try {
            const url = api_url.APP_REBYU_TAGS_GET_ALL;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    },


}