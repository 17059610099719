<template>
  <div class="px-5 py-2">
        <v-tabs
        v-model="tab"
        next-icon="mdi-chevron-right"
        prev-icon="mdi-chevron-left"
        :show-arrows="true"
        right
        >
        <v-tabs-slider color="pink"></v-tabs-slider>
         <v-tab
                v-for="(item, i ) in itemstabs"
                :key="i"
                dark
        >
         {{ item }}
         </v-tab>
       
        </v-tabs>
           <v-tabs-items v-model="tab">
                <v-tab-item >
                    <v-schedule-config :id_entity="id_entity" v-if="tab == 0"  />
                </v-tab-item>
                <v-tab-item >
                    <v-schedule-reservation :id_entity="id_entity" v-if="tab == 1"  />
                </v-tab-item>
                <v-tab-item >
                    <v-schedule-available :id_entity="id_entity" v-if="tab == 2"  />
                </v-tab-item>
          </v-tabs-items>

  </div>
</template>

<script>
import scheduleconf from './components/schedule.config.vue';
import scheduleavailable from './components/schedule.availability.vue';
import schedulereservation from './components/schedule.reservation.vue';

export default {
     props: {
        id_entity: {
            type: String,
            default: ""
        }
    },
    components: {
        'v-schedule-config': scheduleconf,
        'v-schedule-available': scheduleavailable,
        'v-schedule-reservation': schedulereservation
    },
    data: function() {
        return {
            tab: 0,
            itemstabs: ['Servicio', 'Reservación','Configuración'],
        }
    }

}
</script>

<style>

</style>