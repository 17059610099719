<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
    <div class="flex gap-5">
        <label class="text-base font-sans font-bold text-gray-700">País: </label>
        <label class="text-base font-sans text-gray-500">{{item.descCountry ? item.descCountry : ''}}</label>
    </div>
        <div class="flex gap-5">
        <label class="text-base font-sans font-bold text-gray-700">Estado: </label>
        <label class="text-base font-sans text-gray-500">{{item.descState ? item.descState : ''}}</label>
    </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
    <div class="flex gap-5">
        <label class="text-base font-sans font-bold text-gray-700">Municipio: </label>
        <label class="text-base font-sans text-gray-500">{{item.descMunicipality ? item.descMunicipality : ''}}</label>
    </div>
        <div class="flex gap-5">
        <label class="text-base font-sans font-bold text-gray-700">Colonia: </label>
        <label class="text-base font-sans text-gray-500">{{item.suburb ? item.suburb : ''}}</label>
    </div>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
    <div class="flex gap-5">
        <label class="text-base font-sans font-bold text-gray-700">Calle: </label>
        <label class="text-base font-sans text-gray-500">{{item.street ? item.street : ''}}</label>
    </div>
        <div class="flex gap-5">
        <label class="text-base font-sans font-bold text-gray-700">Número Exterior: </label>
        <label class="text-base font-sans text-gray-500">{{item.outdoorNumber ? item.outdoorNumber : ''}}</label>
    </div>
    </div>
     <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
    <div class="flex gap-5">
        <label class="text-base font-sans font-bold text-gray-700">Número Interior: </label>
        <label class="text-base font-sans text-gray-500">{{item.interiorNumber ? item.interiorNumber : ''}}</label>
    </div>
        <div class="flex gap-5">
        <label class="text-base font-sans font-bold text-gray-700">Código Postal: </label>
        <label class="text-base font-sans text-gray-500">{{item.codePostal ? item.codePostal : ''}}</label>
    </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            default: function() {return {}}
        }
    }
}
</script>

<style>

</style>