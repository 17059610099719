<template>
  <div class="w-min md:w-full">

    <!-- Botom Desktop -->
      <v-menu
        transition="slide-y-transition"
        bottom
        >
         <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on"  type="button" class="focus:outline-none  hidden md:flex w-full bg-white opacity-90 px-5 py-0.5 rounded-md justify-center">
                <svg  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
            </svg>
            </button>
        </template>
        <v-card
          class="mx-auto"
          max-width="300"
          tile >
              <v-list :rounded="true" v-if="actions && actions.length > 0">
                  <v-list-item-group >
                    <v-list-item  v-for="item in actions" v-bind:key="item.action" @click="onEvent(item.action)" 
                        class="text-gray-700">
                        <v-list-item-icon>
                            <f-icon :class="{'text-black': item.action != 'ON.DELETE', 'text-red-500': item.action == 'ON.DELETE'}" :icon="['fas', item.icon]"></f-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                        <v-list-item-title :class="{'text-black': item.action != 'ON.DELETE', 'text-red-500': item.action == 'ON.DELETE'}">{{item.label ? item.label : ''}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                </v-list-item-group>
              </v-list>
        </v-card>
      </v-menu>

      <!-- Boton Movil -->
        <button @click="sheet = !sheet"  type="button" class="focus:outline-none  w-min flex md:hidden bg-white opacity-90 px-5 py-0.5 rounded-md justify-center">
                <svg  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path  stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
            </svg>
        </button>
          <v-bottom-sheet v-model="sheet">
            <v-sheet
                class="text-center mt-2xl"
                
            >
            <div class="flex justify-between items-center px-5 py-2 text-black rounded-tl-2xl rounded-tr-2xl">
                     <label class="font-sans tracking-wider text-lg font-bold">Opciones</label>
                    <div>
                        <svg @click="sheet = !sheet" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
              </div>
            <div class="py-5 grid gap-y-5 px-1">
                  <div class="flex justify-between px-5 py-1  items-center"  v-for="item in actions" v-bind:key="item.action" @click="onEvent(item.action)">
                    <div class="flex gap-5 items-center">
                        <div>
                            <f-icon class="text-lg" :class="{'text-black': item.action != 'ON.DELETE', 'text-red-500': item.action == 'ON.DELETE'}" :icon="['fas', item.icon]"></f-icon>
                        </div>
                    <div  class="grid text-left">
                        <label :class="item.action == 'ON.DELETE' ? 'text-red-500' : 'text-black '" class="font-sans text-lg font-normal tracking-wider">{{item.label ? item.label : ''}}</label>
                        <small class="text-gray-400 tracking-wide font-sans">{{item.description ? item.description : ''}}</small>
                    </div>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" :class="item.action == 'ON.DELETE' ? 'text-red-500' : 'text-black'" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                        </svg>
                    </div>
                </div>
            </div>
            </v-sheet>
        </v-bottom-sheet>
  </div>
</template>

<script>
import actionscodes from '../../utils/common/actions'; 

export default {
    props: {
      category: {
              type: Object,
              default: function() {return {}}
          },
    },
    data: function() {
        return {
             sheet: false
        }
    },
    methods: {
      onEvent(event) {
            this.sheet = false;
            this.$emit('event', event);
      }
    },
    computed: {
      actions: function(){
             let btnactions =  [
                {label: 'Actualizar', icon: 'edit', action: actionscodes.EDIT, description: 'Actualiza los datos y fotos de la categoría'},
                {label: this.category && this.category.active ? 'Ocultar' : 'Mostrar', icon: 'eye-slash', action:   this.category && this.category.active ? actionscodes.DISABLED : actionscodes.ENABLED, description: 'Oculta temporalmente la categoría y productos' },
                {label: 'Eliminar', icon: 'trash-alt', action: actionscodes.DELETE, description: 'Elimina permanentemente la categoría'},
            ]
            return btnactions;
         }
    }
}
</script>

<style scoped>

.mt-2xl {
    border-top-right-radius: 1rem !important;
    border-top-left-radius: 1rem !important;
}
</style>