<template>
  <v-card>
       <v-loading :is_loading="isloading" />
       <v-modal-title v-on:close="$emit('close')">
            Actualizar Dirección
       </v-modal-title>
       <v-divider></v-divider>
       <v-skeleton v-if="loading" />
        <v-card-text v-if="!loading">
             <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                <div>
                <label class="text-base font-sans font-semibold">País</label>
                <v-auto-complete
                    v-model="$v.address.idCountry.$model"
                    :vmodel="address.idCountry"
                    :values="countries"
                    :vtext="'name'"
                    :vvalue="'id'"
                    :title="'name'"
                    :isdisabled="true"
                    />
                    <small class="text-error" v-show="$v.address.idCountry.$anyDirty && !$v.address.idCountry.required">* El pais es requerido</small>
                </div>
                  <div>
                    <label class="text-base font-sans font-semibold">Estado</label>
                    <v-auto-complete
                        v-model="$v.address.idState.$model"
                        :vmodel="address.idState"
                        :values="states"
                        :vtext="'name'"
                        :vvalue="'id'"
                        :title="'name'"
                        :disabled="!states"
                        />
                        <small class="text-error" v-show="$v.address.idState.$anyDirty && !$v.address.idState.required">* El estado es requerido</small>
                </div>
             </div>
              <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                <div>
                    <label class="text-base font-sans font-semibold">Municipio</label>
                    <v-auto-complete
                        v-model="$v.address.idMunicipality.$model"
                        :vmodel="address.idMunicipality"
                        :values="municipalities"
                        :vtext="'name'"
                        :vvalue="'id'"
                        :title="'name'"
                        />
                        <small class="text-error" v-show="$v.address.idMunicipality.$anyDirty && !$v.address.idMunicipality.required">* El municipio es requerido</small>
                </div>
                <div>
                    <label class="text-base font-sans font-semibold">Código Postal</label>
                    <v-auto-complete
                        v-model="$v.address.idPostalCode.$model"
                        :vmodel="address.idPostalCode"
                        :values="postcodes"
                        :vtext="'namecode'"
                        :vvalue="'id'"
                        :title="'namecode'"
                        />
                        <small class="text-error" v-show="$v.address.idPostalCode.$anyDirty && !$v.address.idPostalCode.required">* El código postal es requerido</small>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                   <div>
                        <label class="text-base font-sans font-semibold">Colonia</label>
                        <input :disabled="isloading" v-model="$v.address.suburb.$model" class="input-full-fucsia"  placeholder="Ingrese la colonia" />
                        <small class="text-error" v-show="$v.address.suburb.$anyDirty && !$v.address.suburb.required">* La colonia es requerida</small>
                    </div>
                    <div>
                        <label class="text-base font-sans font-semibold">Calle</label>
                        <input :disabled="isloading" v-model="$v.address.street.$model" class="input-full-fucsia"  placeholder="Ingrese la calle" />
                        <small class="text-error" v-show="$v.address.street.$anyDirty && !$v.address.street.required">* La calle es requerida</small>
                    </div>
              </div>
               <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
                   <div>
                        <label class="text-base font-sans font-semibold">Número Exterior</label>
                        <input :disabled="isloading" v-model="$v.address.outdoorNumber.$model" class="input-full-fucsia"  placeholder="Ingrese el número" />
                        <small class="text-error" v-show="$v.address.outdoorNumber.$anyDirty && !$v.address.outdoorNumber.required">* El número es requeridp</small>
                    </div>
                    <div>
                        <label class="text-base font-sans font-semibold">Número Interior</label><span class="text-gray-400 text-xs">(opcional)</span>
                        <input :disabled="isloading" v-model="$v.address.interiorNumber.$model" class="input-full-fucsia"  placeholder="Ingrese el número" />
                        <small class="text-error" v-show="$v.address.interiorNumber.$anyDirty && !$v.address.interiorNumber.required">* El número es requeridp</small>
                    </div>
               </div>
               <div class="grid grid-cols-1 px-1 py-1 gap-5">
                    
                    <div>
                         <label class="text-base font-sans font-semibold">Selecciona la ubicación de tu negocio en el mapa</label>
                         <div>
                            <v-google-maps :vmarkers="markers" v-on:marker="addMarker" />
                         </div>
                         <small class="text-error" v-show="$v.address.coordinates.$anyDirty && !$v.address.coordinates.required">* La ubicación es requerida</small>
                    </div>
                </div>

        </v-card-text>
       <v-card-actions>
          <v-spacer></v-spacer>
            <button :disabled="isloading"
                class="bg-gray-200 text-gray-500 text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none" 
                @click="$emit('close')">Cerrar</button>
            <button :disabled="isloading"
                class="bg-fucsia-lighter text-white text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
              @click="save" >Guardar</button>
        </v-card-actions>
  </v-card>
</template>

<script>
import toastMixin from "@/mixins/vue/toastmixin";
import EntityAddress from '../../../models/entity/entityaddress.model';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { LocationService } from '../../../services/HSuite/Context/hsuite-core-context';
import addressEntityService from '../../../services/entity/addressEntity.service';

export default {
    mixins: [toastMixin, validationMixin],
    props: {
        open: {
            type: Boolean,
            default: false
        },
        id_entity: {
            type: String,
            default: ""
        },
        id: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            isloading: false,
            loading: false,
            address: new EntityAddress(),
            countries: [], 
            states: [], 
            municipalities: [], 
            postcodes: [], 
        }
    },
    validations: {
         address: {
             idCountry: {
                 required
             },
             idState: {
                 required
             },
             idMunicipality: {
                 required
             },
             idPostalCode: {
                 required
             },
             descPostalCode: {
                 required
             },
             codePostal: {
                 required
             },
             suburb: {
                 required
             },
             street: {
                 required
             },
             outdoorNumber: {
                 required
             },
             interiorNumber: {
                 required: false
             },
             descCountry: {
                 required: false
             },
             descState: {
                 required: false
             },
             descMunicipality: {
                 required: false
             },
              coordinates: {
                 required
             },
             latitude: {
                 required
             },
             length: {
                 required
             }         }
    },
    mounted() {
        this.init();
    },
    methods: {
        init: async function() {
            this.loading = true;
            await this.getAddressEntity(this.id);
            await this.getCountry();
            this.loading = false;
        },
        getAddressEntity: async function(id) {
            let response = await addressEntityService.getById(id);
            if(response.success) {
                this.address = response.data;
            }else {
                this.failure(response.message);
            }
        },
        getCountry: async function() {
            let response = await LocationService.getCountry();
            if(response.success) {
                this.countries = response.data;
            }
        },
        getState: async function(id) {
            this.isloading = true;
            let response = await LocationService.getState(id);
            this.isloading = false;
            if(response.success) {
                this.states = response.data;
            }
        },
        getMunipality: async function(id) {
            this.isloading = true;
            let response = await LocationService.getMunicipality(id);
            this.isloading = false;
            if(response.success) {
                this.municipalities = response.data;
            }
        },
        getPostalCodes: async function(id, code) {
            this.isloading = true;
            let response = await LocationService.getPostalCodes(id, code);
            this.isloading = false;
            if(response.success) {
                this.postcodes = response.data;
            }
        },
        save: async function() {
            this.address.codePostal = this.postcode.code;
            this.address.descPostalCode = this.postcode.desc;
            this.address.descCountry = this.descCountry;
            this.address.descState = this.descState;
            this.address.descMunicipality = this.descMunicipality;

            this.$v.$touch();
            if(!this.$v.$anyError) {
                this.isloading = true;
                let response = await addressEntityService.update(this.id, this.address);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                    this.$emit("success");
                }else {
                    this.failure(response.message);
                }
            }
        },
        addMarker(event) {
            const {lat, lng} = event;
             this.address.coordinates = lat.toString() + ',' +lng.toString();
             this.address.latitude = lat.toString();
             this.address.length = lng.toString();
        }
    },
    computed: {
        country: function() {
            return this.address.idCountry;
        },
        state: function() {
            return this.address.idState;
        },
        municipality: function() {
            return this.address.idMunicipality;
        },
        mucipality_code: function() {
            let code = this.municipalities.find(x => x.id == this.address.idMunicipality);
            return code ? code.code : 'NA';
        },
        postcode: function() {
            let code = this.postcodes.find(x => x.id == this.address.idPostalCode);
            return code ? {code: code.code, desc: code.name} : '';
        },
        descCountry: function() {
            let desc =  this.countries.find(x => x.id == this.address.idCountry);
            return desc ? desc.name: '';
        },
        descState: function() {
            let desc =  this.states.find(x => x.id == this.address.idState);
            return desc ? desc.name: '';
        },
        descMunicipality: function() {
            let desc =  this.municipalities.find(x => x.id == this.address.idMunicipality);
            return desc ? desc.name: '';
        },
        markers: function() {
            return {
                lat: this.address.latitude ? parseFloat(this.address.latitude) : "",
                lng: this.address.length ? parseFloat(this.address.length) : ""
            }
        }
    },
    watch: {
        open: function() {
            if(this.open) {
                this.init();
                this.$v.$reset();
                this.address = new EntityAddress();
                 this.countries = [];
            }
        },
        country: function() {
             if(!this.country) {
                this.states = [];
            }
            if(this.country) {
                this.getState(this.country);
            }
        },
        state: function() {
             if(!this.state) {
                this.municipalities = [];
                this.postcodes = [];
                this.address.idMunicipality = '';
                this.codePostal = '';
                this.idPostalCode = '';
            }
            if(this.state) {
                this.getMunipality(this.state);
            }
        },
        municipality: function() {
            if(!this.municipality) {
                this.postcodes = [];
                this.codePostal = '';
                this.idPostalCode = '';
            }
            if(this.municipality) {
                this.getPostalCodes(this.municipality, this.mucipality_code);
            }
        },
        postcode: function() {
             if(!this.postcode) {
                 this.address.suburb = '';
            }
            if(this.postcode) {
                this.address.suburb = this.postcode.desc;
            }
        }
    }
}
</script>

<style>

</style>