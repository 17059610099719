<template>
<div class="m-5 animate-fade-in-up">

  <div class="grid grid-cols-1 md:grid-cols-12 gap-2">

        <div class="col-start-1 col-end-2 md:col-start-1 md:col-end-4 lg:col-end-5 flex items-stretch  shadow-sm">
            <div class="self-center">
                <div class="text-center">
                    <h1 class="font-semibold text-xl text-blueblack mt-2">{{ this.ScheduleType.title }}</h1>
                    <p class="mt-2">{{ this.ScheduleType.description }}</p>
                </div>
                <div class="flex justify-center">
                    <lottie-animation
                            :path="'lottie/horarioatencion.json'"
                            :width="300"
                            :height="300"
                            :speed="1"
                        />
                </div>
            </div>
        </div>

        <div class="col-start-1 col-end-1  md:col-start-4 lg:col-start-5 md:col-end-13 bg-yellow-50-300">

            <div class="flex gap-1 justify-center sm:ml-0 md:ml-2">
                    <div class="" v-if="Configuration">
                        <div v-for="(dia,i) in Configuration.days" :key="i"  class="m-4">
                            <div class="grid grid-cols-1 md:grid-cols-3">
                                <div class="flex items-stretch sm:justify-center md:justify-center lg:justify-start ">
                                    <div class="self-center">
                                        <h6 class="text-base"><strong>{{dia.desc_day}}</strong></h6>
                                    </div>
                                </div>

                                    <div class="flex items-stretch mb-4 sm:justify-center md:justify-center lg:justify-start sm:mt-2 md:mt-2 lg:mt-1">
                                        <div class="self-center">
                                            <div class="toggle colour items-center justify-center">
                                                <input :id="dia.id_day" v-model="dia.active" class="toggle-checkbox hidden " type="checkbox"  v-on:click="dia.active = !dia.active">
                                                <label :for="dia.id_day" class="toggle-label block w-12 h-6 rounded-full transition-color duration-150 ease-out checked"></label>
                                            </div>
                                        </div>
                                    </div>


                                <div class="mb-3 pt-0 ">
                                    <div v-for="(intervalo,x) in dia.intervals" :key="x" class="flex justify-between gap-1 lg:-ml-5">
                                        <v-select :items="items" label="Apertura" item-text="description" item-value="id"  v-model="intervalo.start_time"  :disabled="!dia.active"></v-select>
                                        <h1 class="mt-6 ml-1 mr-1 font-semibold underline">a</h1>
                                        <v-select :items="items" label="Cierre" item-text="description" item-value="id"  :disabled="!dia.active" v-model="intervalo.end_time" ></v-select>
                                            <button v-if="dia.intervals.length > 1" @click="DeleteInterval(i,x)" class="text-gray-400 text-sm hover:text-gray-500">
                                                    <f-icon :icon="['fa', 'trash-alt']" />
                                            </button>
                                    </div>

                                    <div class="flex justify-start -mt-4 cursor-pointer" @click="AddIntervalDay(i)" :id="'dvday_'+i">
                                        <span v-if="dia.active" class="text-sm text-blue cursor-pointer" >Agregar intervalo</span>
                                    </div>
                                </div>
                            </div>
                            <v-divider class="mt-4 mb-4"></v-divider>
                        </div>
                    </div>

            </div>
                <div class="flex sm:justify-center md:justify-center lg:justify-end">
                     <button @click="sendPatch()" class="justify-center gap-2 bg-fucsia-lighter text-white text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none">
                        Guardar
                            <v-icon
                            left
                            small
                            color="white"
                        >
                            mdi-content-save
                        </v-icon>
                    </button>
                </div>

        </div>

  </div>

</div>

</template>

<script>
import cathoursservice from '../../../../services/catalogs/cathours.service';
import scheduleService from '../../../../services/HSuite/HProduct/schedule/schedule.service';
import sweet from '../../../../utils/sweet';

export default {
    props: {
        id_entity: {
            type: String,
            default: ""
        }
    },
    data(){
        return{
            switch1:true,
            posicion:true,
            scheduleCode:'SYST_SCHEDULE_ATTENTION',
            ScheduleType:{},
            Configuration:null,
            items: [],
        }
    },
    mounted() {
         this.init();
    },

    methods:{

        init: async function() {
            await this.getHours();
            await this.getConfiguration();
            await this.getCSchedule();
        },


    /**
     * 
     */

        sendPatch: async function() {
            try {

                if(! await sweet.confirm("¿Desea actualizar el horario seleccionado?","","¡SI!","¡NO!","question")) return;

                let response = await scheduleService.update(this.Configuration.id, this.Configuration);
                if(response.success){
                    this.success(response.message);
                    this.loading = true;
                    await this.getSchedule(this.id_entity);
                    this.loading = false;
                }
                else{
                    this.failure(response.message);
                }
                
            } catch (error) {
                console.log(error);
            }

        }, 



        getHours: async function() {
            let response = await cathoursservice.getAll();
            if(response.success) {
                this.items = response.data;
            }
        },

    /**
     * 
     */
        getConfiguration: async function(){
            
            try {
                let response = await scheduleService.getByEntity(this.id_entity,this.scheduleCode);
                if(response.success) {
                    this.Configuration = response.data;
                }
            } catch (error) {
                console.error(error);
            }
        },

    /**
     * 
     */
        getCSchedule: async function(){
            try {
                let response = await scheduleService.getCSchedule(this.scheduleCode);
                if(response.success) {
                    this.ScheduleType = response.data;
                }
            } catch (error) {
                console.error(error);
            }
        },


        /**
         * 
         */
        AddIntervalDay(day){

            let arrayInit = this.items.find(function(x){
                return x.description == '08:00'
            });

            let arrayEnd = this.items.find(function(x){
                return x.description == '22:00'
            });

            var intervalo = {   
                id_schedule_day_time:null,
                start_time:"1462fa24-c04f-11ec-b1e4-3cd98b304be8",
                end_time:"16afa73e-c04f-11ec-b1e4-3cd98b304be8",
                desc_start_time:"09:00",
                desc_end_time:"10:00"
            }

            if(arrayInit && arrayEnd);{
                intervalo.start_time = arrayInit.id;
                intervalo.desc_start_time = arrayInit.description;
                intervalo.end_time = arrayEnd.id;
                intervalo.desc_end_time = arrayEnd.description;
            }

            this.Configuration.days[day].intervals.push(intervalo);
        },

        /**
         * 
         */
        DeleteInterval(day,interval){
            if(this.Configuration.days[day].intervals[interval]){
                this.Configuration.days[day].intervals.splice(interval,1);
            }
        }

        //#endregion

    },

}
</script>

<style  lang="scss" scoped>

.toggle-label {
   /* @apply relative */
   position: relative;

   &:before {
        /* @apply relative */
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;

       /* @apply relative */
      display: block;
      content: "";

      /* @apply relative */
      width: 1.25rem;
      height: 1.25rem;

     /* @apply relative */
      border-radius: 9999%;

    /* @apply relative */
      background-color: white;
      background-position: center;
      background-repeat: no-repeat;

      background-size: 40%;
      background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23a0aec0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');

      //   /* @apply relative */
      transition: transform .15s cubic-bezier(0, 0, 0.2, 1);

       /* @apply relative */
      transform: translateX(0);

        /* @apply relative */
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
         0 2px 4px -1px rgba(0, 0, 0, 0.06);
   }
}

.toggle-checkbox:checked + .toggle-label:before {
     /* @apply relative */
   transform: translateX(1.5rem);
   background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23a0aec0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
}

.toggle {
   &.slim {
      .toggle-label:before {
         top: -0.15rem;

            /* @apply relative */
         left: 0;
      }

      .toggle-checkbox:checked + .toggle-label:before {
            /* @apply relative */
         transform: translateX(1.75rem);
      }
   }

   &.colour {
      .toggle-label {
           /* @apply relative */
         background-color: #E91E63;
      }

      .toggle-checkbox:checked + .toggle-label {
            /* @apply relative */
         background-color: #4DB6AC;
      }
   }
}


</style>