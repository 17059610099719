import ApiRebyu from '../config/ApiRebyu';
import api_url from '../../utils/api.url';

export default  {

    /**
     * Función que se encarga de guardar los datos de la dirección de la entidad
     * @param {any} data Modelo de datos de la dirección
     * @returns 
     */
     async save(data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_ADDRESS_SAVE;
            return await ApiRebyu.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    }, 
    /**
     * Función que permite obtener los datos de la dirección de la entidad
     * @param {String} id Identificador de la entidad
     * @returns 
     */
     async getByIdEntity(id) {
        try {
            const url = api_url.APP_REBYU_ENTITY_ADDRESS_GET_BY_ID_ENTITY + id;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Función que permite obtener todas las direcciones de la entidad
     * @param {String} id Identificador de la entidad
     * @returns 
     */
     async getAllByIdEntity(id) {
        try {
            const url = api_url.APP_REBYU_ENTITY_ADDRESS_GET_ALL_BY_ID_ENTITY + id;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    }, 
    /**
     * Función que permite obtener los datos de la dirección por ID
     * @param {String} id Identificador de la dirección
     * @returns 
     */
     async getById(id) {
        try {
            const url = api_url.APP_REBYU_ENTITY_ADDRESS_GET_BY_ID + id;
            return await ApiRebyu.get(url);
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Función que permite Actualizar los datos de la dirección
     * @param {String} id Identificador de la dirección
     * @param {*} data Modelo de datos de la dirección
     * @returns 
     */
    async update(id, data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_ADDRESS_UPDATE + id;
            return await ApiRebyu.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    }, 

    /**
     * Función que permite Actualizar la dirección actual de la entidad
     * @param {String} id Identificador de la dirección
     * @param {*} data Modelo de datos de la dirección
     * @returns 
     */
    async addressSet(id, data) {
        try {
            const url = api_url.APP_REBYU_ENTITY_ADDRESS_SET + id;
            return await ApiRebyu.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    }, 

}