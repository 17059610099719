<template>
  <div class="sm:px-2 md:px-5 py-2 mb-5">
   <v-loading :is_loading="isloading" />
   <div class="col-start-1 col-end-1 md:col-start-8 md:col-end-13 border sm:border-white md:border-gray-200 mt-2 shadow-sm rounded-md">
       <div class="h-10 flex items-center gap-2 text-gray-500 font-bold bg-gray-100 px-5 py-1 font-sans tracking-wide sm:rounded-tr-none sm:rounded-tl-none md:rounded-tr-md md:rounded-tl-md border-b sm:border-none md:border-gray-200">
               <f-icon :icon="['fas', 'qrcode']" />
              <small>CÓDIGOS QR Y ENLACES</small>
        </div>

        <div class="w-full flex flex-wrap gap-10 gap-y-3 justify-between sm:px-1 lg:px-20 sm:py-1 lg:py-5">
          <div class="sm:h-auto md:h-60 px-5 py-1 sm:w-full lg:w-1/45 border border-gray-200 rounded-md" v-for="(link, i) in sharedlinks" :key="i">
          <div class="flex justify-between items-center">
             <label class="font-sans font-bold tracking-wide text-gray-500 text-2xl">{{link.name ? link.name : ''}}</label>
             <svg v-if="link.active && link.id_shared_link" @click="status(false,link.id_shared_link)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye-slash text-gray-400 cursor-pointer hover:text-gray-500" viewBox="0 0 16 16">
              <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
              <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
              <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
            </svg>
             <svg v-if="!link.active && link.id_shared_link" @click="status(true,link.id_shared_link)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye text-gray-400 cursor-pointer hover:text-gray-500" viewBox="0 0 16 16">
                <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
              </svg>
          </div>
             <v-divider></v-divider>
             <div class="grid grid-cols-1 md:grid-cols-3">
               <div class="col-span-1">
                 <div class="flex  justify-center" v-if="!link.id_shared_link">
                   <img :src="defaultqr" class="max-h-48 filter blur-sm">
                 </div>
                 <div class="flex justify-center py-5" :class="{'filter blur-sm' : !link.active}" v-if="link.id_shared_link">
                    <qrcode-vue :margin="2" :id="'mycanvas'+i" :value="link.link" :size="160" level="H" :render-as="'canvas'"/>
                 </div>
               </div>
               <div class="sm:col-span-1 md:col-span-2 px-2 py-1 grid items-center justify-between">
                 <div class="grid gap-y-3">
                   <span class="text-xs md:text-sm text-justify tracking-wide font-sans text-gray-500">{{ link.description ? link.description: ''}}</span>
                   <div class="flex">
                     <span class="flex gap-2"><h5 class="font-bold font-sans text-black tracking-wide">GENERA</h5> <h5 class="font-semibold font-sans text-fucsia-lighter tracking-wide"> Y COMPARTE</h5></span>
                   </div>
                 </div>
                 <div class="flex">
                      <input :disabled="true" v-model="link.link" class="input-full-fucsia bg-gray-100 rounded-tr-none rounded-br-none text-gray-500"  placeholder="Enlace publico" />
                      <button  @click="onsave(link.id_shared_link, link.code_type)" :class="{'rounded-tr-md rounded-br-md px-4': !link.id_shared_link}"  class="bg-fucsia-lighter hover:bg-fucsia-dark  px-1 text-white shadow-md">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                          <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"/>
                          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"/>
                        </svg>
                      </button>
                       <button v-if="link.id_shared_link" @click="copy(link.link)"  class="px-1 text-white shadow-md" style="background-color: #06d6a0;">
                       <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-clipboard" viewBox="0 0 16 16">
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"/>
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"/>
                        </svg>
                      </button>
                       <button v-if="link.id_shared_link" @click="downloadQR(i, link.code, link.code_type)" class="px-1 text-white shadow-md rounded-tr-md rounded-br-md" style="background-color: #1c5d99;">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-cloud-download" viewBox="0 0 16 16">
                              <path d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/>
                              <path d="M7.646 15.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 14.293V5.5a.5.5 0 0 0-1 0v8.793l-2.146-2.147a.5.5 0 0 0-.708.708l3 3z"/>
                            </svg>
                      </button>
                 </div>
               </div>

             </div>
          </div>
        </div>
        
   </div>
  </div>
</template>

<script>
import ToastMixin from '@/mixins/vue/toastmixin';
import globalMixin from '@/mixins/globalMixin';
import sharedLinkService from '../../services/shared/sharedlink.service';
import sharedLink from '../../models/shared/sharedlink.model';


export default {
  mixins: [ToastMixin,  globalMixin],
  props: {
    id_entity: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      APP_URL: process.env.VUE_APP_URL,
      isloading: false,
      defaultqr: 'https://res.cloudinary.com/dx3omcygd/image/upload/v1643993933/admin/rebyuqr_gaofh1.png',
      sharedlinks: [],
      sharedlink: new sharedLink(),
      clipboard_value: ''
    }
  },

  mounted() {
    this.init();
  },
  methods: {
    init: async function() {
      await this.getSharedLinks(this.id_entity);
    },
    getSharedLinks: async function(id) {
      let response = await sharedLinkService.getBYIdEntity(id);
      if(response.success) {
        this.sharedlinks = response.data;
        for(let i = 0; i < this.sharedlinks.length; i++) {
          if(this.sharedlinks[i].id_shared_link) {
            this.sharedlinks[i].link = `${this.APP_URL}${this.sharedlinks[i].path}`
          }
        }
      }
    },
    onsave: async function(id, code) {
       if(!id) {
         this.save(code);
       }else {
          let confirm = await this.confirm('Actualizar Enlace', '¿Desea generar un nuevo enlace?');
          if(confirm) {
             this.save(code);
          }
       }
    },
    save: async function(code) {
         this.sharedlink.code = code;
         this.sharedlink.id_entity = this.id_entity;
         this.isloading = true;
         let response = await sharedLinkService.save(this.sharedlink);
         this.isloading = false;
         if(response.success) {
            this.success(response.message);
            this.init();
         }else {
           this.failure(response.message);
         }
    },
    status: async function (value, id) {
      let confirm = await this.confirm(`${value ? 'Activar Enlace': 'Desactivar Enlace'}`, `Deseas ${value ? 'activar': 'desactivar'} el enlace a tus clientes?`);
      if(confirm) {
        let data = {
          id_entity: this.id_entity,
          active: value,
        }
        let response = await sharedLinkService.status(id, data);
        if(response.success) {
          this.success(response.message);
          this.init();
        }else {
          this.failure(response.message);
        }
      }
    },
     copy(e) {
       var copyText = e ? e: '';
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(copyText);

        this.success('Texto copiado correctamente', '',  1000);
    },
    downloadQR(index, code, codetype){
        var parent = document.getElementById('mycanvas'+ index);
        let canvas = parent.getElementsByTagName('canvas');
        var canvasImage     = canvas[0].toDataURL("image/png");
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function () {
            let a = document.createElement('a');
            a.href = window.URL.createObjectURL(xhr.response);
            a.download = 'QR_'+codetype+'_'+code+'_.png';
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            a.remove();
          };
          xhr.open('GET', canvasImage); // This is to download the canvas Image
          xhr.send();
    }
  }
}
</script>

<style>

</style>