<template>
  <div>

    <div>
            <v-dialog 
            v-if="iscreate"
            v-model="iscreate"
            persistent
            max-width="500px"
            :retain-focus="false"
            >
            <v-modal :id_entity="id_entity"  v-on:close="iscreate = false" v-on:success="onsuccess" />
            </v-dialog>
    </div>

    <div class="m-5 animate-fade-in-up">
        <!-- LOADER -->
        <div class="grid grid-cols-1 md:grid-cols-12 gap-2" v-if="loading">
            <div class="col-start-1 col-end-2 md:col-start-1 md:col-end-4 lg:col-end-5">
                <v-skeleton :type="'list-item-two-line, list-item-two-line, list-item-two-line'"  />
            </div>
            <div class="col-start-1 col-end-1  md:col-start-4 lg:col-start-5 md:col-end-13 sm:px-2 md:px-10">
                <v-skeleton :type="'card-avatar, list-item-two-line'" :width="'100%'" />
            </div>
        </div>

        <!-- INFORMACIÓN -->
    <div class="grid grid-cols-1 md:grid-cols-12 gap-2" v-else>
        <!-- <p>{{ this.items_schedules }}</p> -->
    
            <div class="col-start-1 col-end-2 md:col-start-1 md:col-end-4 lg:col-end-5 flex items-stretch  shadow-sm">
                <div class="grid gap-y-2 w-full">
                    <div>
                        <div @click="iscreate=true" class="px-5 py-2 border border-gray-500 rounded-md bg-gray-50 w-max cursor-pointer flex items-center hover:bg-gray-100">
                                <v-icon>mdi-plus</v-icon>
                                <span class="font-sans text-md tracking-wide text-gray-500">Nuevo horario</span>
                        </div>
                        <v-divider class="py-1 mt-3"></v-divider>
                        <span class="font-sans text-md tracking-wide text-gray-500">Horarios de Atención</span>
                        <v-list flat class="w-min">
                            <v-list-item-group v-model="selectedscheduleindex"  color="pink"  >
                                <!-- .filter((x)=>{ return x.type == 'SYST_SCHEDULE_ATTENTION' })"  -->
                                <v-list-item
                                v-for="(item, index) in items_schedules.filter((x)=>{ return x.type == 'SYST_SCHEDULE_ATTENTION' })"
                                :key="index+'att'"
                                >
                                <v-list-item-action>
                                    <v-icon>mdi-send</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.name }} - {{ item.active ? 'Activo' : 'Inactivo' }} </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>
                                <span class="font-sans text-md tracking-wide text-gray-500">Horarios de Reservación</span>
                                <v-divider></v-divider>
                                <v-list-item
                                v-for="(item, index) in items_schedules.filter((x)=>{ return x.type == 'SYST_SCHEDULE_BOOKING' })"
                                :key="index+'boo'"
                                >
                                <v-list-item-action>
                                    <v-icon>mdi-send</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title>{{ item.name }} - {{ item.active ? 'Activo' : 'Inactivo' }} </v-list-item-title>
                                </v-list-item-content>
                                </v-list-item>

                            </v-list-item-group>
                        </v-list>
                    </div>
                </div>
                <v-divider></v-divider>    
            </div>

            <div class="col-start-1 col-end-1  md:col-start-4 lg:col-start-5 md:col-end-13 bg-yellow-50-300">
                
                
            <!-- <div class="col-start-1 col-end-2 md:col-start-1 md:col-end-4 lg:col-end-5 flex items-stretch  shadow-sm"> -->
                <div v-if="selectedschedule" class="grid gap-y-2 w-full">
                    <div>
                        <div class="px-0 mt-0 rounded-md  w-max cursor-pointer flex items-center hover:bg-gray-100">
                        <!-- <p>{{ selectedschedule.active }}</p> -->
                        <v-switch class="mt-0" :label="selectedschedule.active ? 'Activo' : 'Activar'" v-model="items_schedules[selectedscheduleindex].active" @change="eventActiveSchedule()"/>
                        
                        </div>
                        <v-divider class="py-1 mt-3"></v-divider>
                    </div>
                </div>
            <!-- </div> -->
    

                <div class="flex gap-1 justify-center sm:ml-0 md:ml-2">
                    <div class="" v-if="selectedschedule">
                        <div v-for="(dia,i) in selectedschedule.days" :key="i"  class="m-4">
                            <div class="grid grid-cols-1 md:grid-cols-3">
                                <div class="flex items-stretch sm:justify-center md:justify-center lg:justify-start ">
                                    <div class="self-center">
                                        <h6 class="text-base"><strong>{{dia.desc_day}}</strong></h6>
                                    </div>
                                </div>
                            
                                    <div class="flex items-stretch mb-4 sm:justify-center md:justify-center lg:justify-start sm:mt-2 md:mt-2 lg:mt-1">
                                        <div class="self-center">
                                            <div class="toggle colour items-center justify-center">
                                                <input :id="'btntoggle'+dia.id_day" v-model="dia.active" class="toggle-checkbox hidden " type="checkbox"  v-on:click="dia.active = !dia.active">
                                                <label :for="'btntoggle'+dia.id_day" class="toggle-label block w-12 h-6 rounded-full transition-color duration-150 ease-out checked"></label>
                                            </div>
                                        </div>
                                    </div>

                                
                                <div class="mb-3 pt-0 ">
                                    <div v-for="(intervalo,x) in dia.intervals" :key="x" class="flex justify-between gap-1 lg:-ml-5">
                                        <v-select :items="items" label="Apertura" item-text="description" item-value="id"  v-model="intervalo.start_time" :disabled="!dia.active"></v-select>
                                        <h1 class="mt-6 ml-1 mr-1 font-semibold underline">a</h1>
                                        <v-select :items="items" label="Cierre" item-text="description" item-value="id" v-model="intervalo.end_time" :disabled="!dia.active"></v-select>
                                            <button v-if="dia.intervals.length > 1" @click="DeleteInterval(i,x)" class="text-gray-400 text-sm hover:text-gray-500">
                                                    <f-icon :icon="['fa', 'trash-alt']" />
                                            </button>
                                    </div>
                                    <div class="flex justify-start -mt-4">
                                        <span v-if="dia.active" class="text-sm text-blue cursor-pointer" @click="AddIntervalDay(i,'dvday_'+i)">Agregar intervalo</span>
                                    </div>
                                </div>
                            </div>
                            <v-divider></v-divider>
                        </div>
                    </div>

                </div>
                <div class="flex sm:justify-center md:justify-center lg:justify-end">
                     <button @click="sendPatch()" class="justify-center gap-2 bg-fucsia-lighter text-white text-lg tracking-wide mr-2 px-3 py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none">
                        Guardar
                            <v-icon
                            left
                            small
                            color="white"
                        >
                            mdi-content-save
                        </v-icon>
                    </button>
                </div>
            </div>
    </div>

    </div>  
  </div>
</template>

<script>
import toastMixin from "@/mixins/vue/toastmixin";
import cathoursservice from '../../../../services/catalogs/cathours.service';
import scheduleService from '../../../../services/HSuite/HProduct/schedule/schedule.service';
import modal from './modal.add.vue';
import sweet from '../../../../utils/sweet';

export default {
mixins: [toastMixin],
 components:{
    'v-modal':modal
 },
 props: {
        id_entity: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
        iscreate:false,
        switch1:true,
        posicion:true,
        loading: false,
        scheduleCode:'na',
        lista_dias: [],
        items_schedules: [],
        items: [],
        selectedscheduleindex: 0
    }
    },
    mounted() {
        this.init();
        
    },
    methods: {

    onsuccess: function() {
        this.iscreate = false;
        this.init();
    },
        
        init: async function() {
            this.loading = true;
            await this.getHours();
            await this.getSchedule(this.id_entity);
            this.loading = false;
        },
        getHours: async function() {
            let response = await cathoursservice.getAll();
            if(response.success) {
                this.items = response.data;
            }
        },
        getSchedule: async function(id) {
            let response = await scheduleService.getByEntity(id,this.scheduleCode,1);
            if(response.success) {
                if( Array.isArray(response.data))
                    this.items_schedules = response.data;
                else 
                    this.items_schedules = [response.data];

                this.items_schedules = this.items_schedules.sort(function (a, b) {
                                                                                                if (a.type > b.type) {
                                                                                                    return 1;
                                                                                                }
                                                                                                if (a.type < b.type) {
                                                                                                    return -1;
                                                                                                }
                                                                                                // a must be equal to b
                                                                                                return 0;
                                                                                            });
                    
            }
        },

        DeleteInterval(day,interval){
            if(this.items_schedules[this.selectedscheduleindex].days[day].intervals[interval]){
                this.items_schedules[this.selectedscheduleindex].days[day].intervals.splice(interval,1);
            }
        },

        eventActiveSchedule: async function(){

            try {

                let value = this.selectedschedule;

                if(!value.active){
                        this.items_schedules[this.selectedscheduleindex].active = !value.active;
                        this.failure('Debes contar con un horario activo','Aviso');
                    return;
                }

                let response = await scheduleService.updateActiveSchedule( { id : value.id , id_entity : this.id_entity } );

                if(response.success){

                        for (let index = 0; index < this.items_schedules.length; index++) {
                                if(index!=this.selectedscheduleindex) this.items_schedules[index].active = !value.active;
                        }
                }
                
            } catch (error) {
                console.log(error);
            }

        },

        /**
         * 
         */
        AddIntervalDay(day){

            let arrayInit = this.items.find(function(x){
                return x.description == '08:00'
            });

            let arrayEnd = this.items.find(function(x){
                return x.description == '22:00'
            });

            var intervalo = {   
                id_schedule_day_time:null,
                start_time:"1462fa24-c04f-11ec-b1e4-3cd98b304be8",
                end_time:"16afa73e-c04f-11ec-b1e4-3cd98b304be8",
                desc_start_time:"09:00",
                desc_end_time:"10:00"
            }

            if(arrayInit && arrayEnd);{
                intervalo.start_time = arrayInit.id;
                intervalo.desc_start_time = arrayInit.description;
                intervalo.end_time = arrayEnd.id;
                intervalo.desc_end_time = arrayEnd.description;
            }

            this.items_schedules[this.selectedscheduleindex].days[day].intervals.push(intervalo);
        },

        sendPatch: async function(){

            try {

                if(! await sweet.confirm("¿Desea actualizar el horario seleccionado?","","¡SI!","¡NO!","question")) return;

                let response = await scheduleService.update(this.selectedschedule.id, this.selectedschedule);
                if(response.success){
                    this.success(response.message);
                    this.loading = true;
                    await this.getSchedule(this.id_entity);
                    this.loading = false;
                }
                else{
                    this.failure(response.message);
                }
                
            } catch (error) {
                console.log(error);
            }
            

        }

    },
    computed: {
        selectedschedule: function() {
            return this.items_schedules[this.selectedscheduleindex] ? this.items_schedules[this.selectedscheduleindex] : null;
        }

    }
}
</script>


<style  lang="scss" scoped>

.toggle-label {
   /* @apply relative */
   position: relative;

   &:before {
        /* @apply relative */
      position: absolute;
      top: 0.125rem;
      left: 0.125rem;

       /* @apply relative */
      display: block;
      content: "";

      /* @apply relative */
      width: 1.25rem;
      height: 1.25rem;

     /* @apply relative */
      border-radius: 9999%;

    /* @apply relative */
      background-color: white;
      background-position: center;
      background-repeat: no-repeat;

      background-size: 40%;
      background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23a0aec0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>');

      //   /* @apply relative */
      transition: transform .15s cubic-bezier(0, 0, 0.2, 1);

       /* @apply relative */
      transform: translateX(0);

        /* @apply relative */
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
         0 2px 4px -1px rgba(0, 0, 0, 0.06);
   }
}

.toggle-checkbox:checked + .toggle-label:before {
     /* @apply relative */
   transform: translateX(1.5rem);
   background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="%23a0aec0" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>');
}

.toggle {
   &.slim {
      .toggle-label:before {
         top: -0.15rem;

            /* @apply relative */
         left: 0;
      }

      .toggle-checkbox:checked + .toggle-label:before {
            /* @apply relative */
         transform: translateX(1.75rem);
      }
   }

   &.colour {
      .toggle-label {
           /* @apply relative */
         background-color: #E91E63;
      }

      .toggle-checkbox:checked + .toggle-label {
            /* @apply relative */
         background-color: #4DB6AC;
      }
   }
}


</style>