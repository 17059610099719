<template>
<div class="min-screen-content">
     <v-loading :is_loading="isloading" />
  <div class="sm:px-5 md:px-10 sm:py-4 md:py-5 h-auto bg-gray-100  grid grid-flow-row">
         
            <div class="grid grid-flow-row md:grid-cols-12 gap-y-2  h-auto" style="min-height:80%">
                <div class="col-span-12 relative h-auto rounded-tl-md rounded-tr-lg shadow-md max-h-full bg-white"  style="min-height:300px">
                        <div class="grid static">
                            <div class="h-64 w-full bg-gray-300 rounded-tl-md rounded-tr-lg absolute bg-cover bg-center" :style="`background-image: url('`+entity.CoverPhoto+`')`">
                                <div class="flex justify-between items-center">
                                    <div class="h-64  w-10 md:w-20 backdrop-filter backdrop-blur-sm bg-white/30 md:backdrop-blur-lg"></div>
                                     <svg v-if="!entity.CoverPhoto" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>
                                    <div class="h-64  w-10 md:w-20 backdrop-filter backdrop-blur-sm bg-white/30 md:backdrop-blur-lg"></div>
                                    <div class="absolute bottom-2 right-1 text-gray-400 font-sans text-xs tracking-wider font-bold" v-if="!entity.CoverPhoto">1300x250</div>
                                </div>
                                 <div @click="back" class="w-12 h-12 rounded-full bg-gray-900  absolute top-2 left-2 opacity-80 cursor-pointer">
                                        <div class="h-12 flex justify-center items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                                            </svg>
                                        </div>
                                    </div>
                                 <v-dialog 
                                    v-model="iscoverphoto"
                                    persistent
                                    max-width="1300px"
                                    v-security="{acm: $route.meta.acm, acc: acc.EDIT_COVER_PHOTO_ENTITY}"
                                    >
                                    <template  v-slot:activator="{ on, attrs }" >
                                        <div v-bind="attrs" v-on="on" class="w-12 h-12 rounded-full bg-gray-900  absolute top-2 right-2 opacity-80">
                                            <div class="h-12 flex justify-center items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </template>
                                    <v-cropper :height="250" :width="1300" :open="iscoverphoto" v-on:close="iscoverphoto = false" v-on:save="onUploadedCover" />
                                 </v-dialog>
                            </div>
                            <div class="h-40 w-40 rounded-full bg-gray-400 relative top-40 left-1/4 md:left-10 bg-cover bg-center" :style="`background-image: url('`+entity.ProfilePicture+`')`">
                                   <v-dialog 
                                    v-model="isprofilephoto"
                                    persistent
                                    max-width="700px"
                                     v-security="{acm: $route.meta.acm, acc: acc.EDIT_PROFILE_PHOTO_ENTITY}"
                                    >
                                    <template  v-slot:activator="{ on, attrs }" >
                                        <div v-bind="attrs" v-on="on" class="w-12 h-12 rounded-full bg-gray-900  absolute bottom-1 -right-0 opacity-80">
                                            
                                            <div class="h-12 flex justify-center items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                                </svg>
                                            </div>
                                        </div>
                                    </template>
                                     <v-cropper :height="500" :width="500" :open="isprofilephoto" v-on:close="isprofilephoto = false" v-on:save="onUploadedProfile" />
                                </v-dialog>
                                <div class="w-full h-full flex items-center justify-center" v-if="!entity.ProfilePicture">
                                   <div class="absolute bottom-5 text-gray-500 font-sans text-xs tracking-wider font-bold z-50">500x500</div>  
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                    </svg>  
                                </div>
                            </div>
                            </div>
                            <div class="relative md:flex grid-cols-12 bottom-0 md:bottom-1 md:ml-56 justify-between items-center px-5 mt-40 md:mt-28 mb-2">
                                    <div class="grid col-span-12">
                                        <label class="font-sans tracking-wide font-bold text-3xl md:text-4xl text-black">{{entity.name ? entity.name : ''}}</label>
                                        <label class="text-gray-500 font-sans text-lg md:text-xl break-all overflow-ellipsis truncate">{{entity.tagname ? '@'+entity.tagname : ''}}</label>
                                    </div>
                                    <div class=" ">
                                        <div class="flex gap-1 justify-end items-center">
                                           
                                                <router-link :to="`/${entity.tagname}`" class="w-full" v-security="{acm: $route.meta.acm, acc: acc.PREVIEW_ENTITY}">
                                                    <button class="btn-full-fucsia hover:shadow-md">Previsualizar</button>
                                                </router-link>
                                           
                                            <div class="w-1/4">
                                                <rb-options :actions="actions" v-on:event="(event) => onEvent(event, item)">
                                                <template #buttonsheet>
                                                    <rb-button-options :bntcss="'text-gray-400 sm:bg-gray-200 md:bg-transparent hover:bg-gray-100'" :iconcss="'text-gray-400'"/>
                                                </template>
                                                <template #buttonmenu>
                                                <rb-button-options :bntcss="'text-gray-400 sm:bg-gray-200 md:bg-transparent hover:bg-gray-100'" :iconcss="'text-gray-400'"/>
                                                </template>
                                            </rb-options>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                    </div>
                    
                    <div class="col-span-12 bg-white shadow-md overflow-hidden">
                        <div >
                            <v-tabs
                            v-model="tab"
                            next-icon="mdi-chevron-right"
                            prev-icon="mdi-chevron-left"
                            :show-arrows="true"
                            >
                            <v-tabs-slider color="pink"></v-tabs-slider>

                            <v-tab
                                v-for="(item, i ) in itemstabs"
                                :key="i"
                                dark
                            >
                            <div class="flex gap-1 items-center">
                                {{ item }}
                                <svg v-if="!entity.has_address && i == 2" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                                <svg v-if="!entity.has_tags && i == 1" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                                <svg v-if="(!entity.has_config && i == 3) || (!hasconf && i == 3 )" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                </svg>
                            </div>
                            </v-tab>
                            </v-tabs>
                             <v-tabs-items v-model="tab">
                                 <v-tab-item >
                                     <v-entity-category :id_entity="id" v-if="tab == 0"  />
                                 </v-tab-item>
                                 <v-tab-item  >
                                     <v-entity-information :id_entity="id" v-if="tab == 1" />
                                 </v-tab-item>
                                 <v-tab-item  >
                                     <v-entity-address :id_entity="id" v-if="tab == 2" />
                                 </v-tab-item>
                                 <v-tab-item  >
                                     <v-entity-configuration :id_entity="id" v-if="tab == 3" v-on:disableconfig="(val) => hasconf = val" />
                                 </v-tab-item>
                                <v-tab-item  >
                                     <v-schedule-index :id_entity="id" v-if="tab == 4" />
                                 </v-tab-item>
                                  <v-tab-item>
                                      <v-qr-index :id_entity="id" v-if="tab == 5" />
                                  </v-tab-item>
                                  <v-tab-item>
                                      <v-collaborators :id_entity="id" v-if="tab == 6" />
                                  </v-tab-item>
                             </v-tabs-items>
                        </div>
                    </div>
            </div>
  </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {MODULES, TYPES} from '../../store/common/HMVuexCommon';
import RBButtonOptionsVue from '../../@core/components/form/button/RBButtonOptions.vue';
import ToastMixin from '@/mixins/vue/toastmixin';
import globalMixin from '@/mixins/globalMixin';
import { EntityService } from '../../services/HSuite/Context/hsuite-rebyu-context'
import SecurityMixin from '@/mixins/security/RBSecurityMixin';
import category from './category.vue';
import information from './information.vue';
import address from './address.vue';
import configuration from './configuration.vue';
import schedule from './schedule/index.vue';
import qrview from './qr.vue';
import collaboratorsview from './collaborators.vue';
import actions from '../../utils/common/actions';
import EntityCover from '../../models/entity/entitycover.model';
import EntityProfile from '../../models/entity/entityprofile.model';
import file from '../../utils/file/file';
import cloudStorageService from '../../services/firebase/cloudstorage.service';
import commoncontext from '../../utils/common/firebasecontext';


export default {
    mixins: [ToastMixin, globalMixin, SecurityMixin],
    props: {
        id: {
            type: String,
            default: ""
        }
    },
    components: {
        'v-entity-category' : category,
        'v-entity-information' : information,
        'v-entity-address' : address,
        'v-entity-configuration' : configuration,
        'v-schedule-index' : schedule,
        'v-qr-index' : qrview,
        'rb-button-options': RBButtonOptionsVue,
        'v-collaborators': collaboratorsview
    },
    data: function() {
        return {
            iscoverphoto: false,
            isprofilephoto: false,
            isloading: false,
            itemstabs: ['Categorias', 'Información', 'Dirección' ,'Configuración','Horario', 'QR', 'Colaboradores'],
            tab: 0,
            entity: {},
            hasconf: true,
            
        }
    },
    mounted() {
        this.init();
    },
    methods : {
        
        init: async function() {
            this.isloading = true;
            await this.getEntity(this.id);
            this.isloading = false;
        },
        ...mapActions( MODULES.ENTITY, [
            TYPES.RB_ENTITY_GET_ENTITY
        ]),
        onEvent: function (event, item) {
              switch(event) {
                case actions.DISABLED:
                    this.onEnabledStore(item.id, false);
                break;
                case actions.ENABLED:
                    this.onEnabledStore(item.id, true);
                break;
                case actions.QRCODE:
                    this.GenerateQr(item);
                break;
                 case actions.EDIT:
                      this.$router.push(`/entity/edit/${item.id}`)
                break;
            }
         },
         onEnabledStore: async function(id, status) {
            let confirm = await this.confirm(`${!status ? 'Ocultar Tienda' : 'Mostrar Tienda'}`, `${!status ? '¿Deseas ocultar la tienda a los usuarios?, esta acción se puede revertir' : '¿Desea hacer disponible la tienda a los usuarios?'}` );
            if(confirm) {
                this.isloading = true;
                let data = { active: status}
                let response = await EntityService.status(id, data);
                this.isloading = false;
                if(response.success) {
                    this.success(response.message);
                     this.init();
                }else {
                    this.failure(response.message);
                }
            }
        },
       
        back() {
            this.$router.go(-1);
        },

        onUploadedCover: async function (data) {
            this.iscoverphoto = false;
            const blob = await file.b64toBlob(data, 'image/jpeg');

            this.isloading = true;
            const responseCloud = await cloudStorageService.uploadFile(commoncontext.ENTITY, blob, this.entity.cover_public_id);
            let entitycover = new EntityCover();
            entitycover.url = responseCloud.url;
            entitycover.fullpath = responseCloud.fullPath;
            entitycover.public_id = responseCloud.public_id;
            entitycover.idEntity = this.id;
           
            let response =  await EntityService.saveCoverPicture(entitycover);
            this.isloading = false;
            if(response.success) {
                this.success(response.message);
                this.init();
            }else {
                 this.failure(response.message);
            }
        },
        onUploadedProfile: async function (data) {
            this.isprofilephoto = false;
            const blob = await file.b64toBlob(data, 'image/jpeg');

            this.isloading = true;
            const responseCloud = await cloudStorageService.uploadFile(commoncontext.ENTITY, blob, this.entity.profile_public_id);

            this.entity.ProfilePictureFile = data;
            let entityprofile = new EntityProfile();
            entityprofile.url = responseCloud.url;
            entityprofile.fullpath = responseCloud.fullPath;
            entityprofile.public_id = responseCloud.public_id;

            entityprofile.idEntity = this.id;
         
            let response =  await EntityService.saveProfilePicture(entityprofile);
            this.isloading = false;
            if(response.success) {
                this.success(response.message);
                this.init();
            }else {
                 this.failure(response.message);
            }

        },
    },
    computed: {
         ...mapGetters(MODULES.ENTITY, {
            entityData: TYPES.RB_ENTITY_GET_ENTITY
         }),
         actions: function(){
             let btnactions =  [
                {label: 'Actualizar', icon: 'edit', action: actions.EDIT, description: 'Actualiza los datos y fotos de tu tienda', acm: this.acm.ENTITYDETAIL.name, acc: this.acc.UPDATE_ENTITY},
                {label: this.entity && this.entity.active ? 'Ocultar' : 'Mostrar', icon: 'eye-slash', 
                    action:   this.entity && this.entity.active? actions.DISABLED : actions.ENABLED, description: 'Oculta temporalmente tu tienda', acm: this.acm.ENTITYDETAIL.name, acc: this.acc.HIDDEN_ENTITY },
                {label: 'Eliminar', icon: 'trash-alt', action: actions.DELETE, description: 'Elimina permanentemente tu tienda', acm: this.acm.ENTITYDETAIL.name, acc: this.acc.DELETE_ENTITY},
            ]
            return btnactions;
         }
    },
    watch: {
        entityData: function() {
            if(this.entityData) {
                this.entity = this.entityData
            }
        }
    }
}
</script>

<style>

</style>