import ApiProducts from "../config/ApiProducts";
import api_url from '../../utils/api.url';

export default  {

     /**
     * Funcion que permite obtener la de horas
     * @returns 
     */
      async getAll() {
        try {
            const url = api_url.APP_PRODUCTS_CAT_HOURS_GET_ALL;
            return await ApiProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },


}