export default class Category {
    constructor(
        idEntity,
        name,
        id_type,
        image,
        description,
        public_id,
        fullpath
    ) {
        this.idEntity = idEntity ? idEntity : '',
        this.name = name ? name : '',
        this.id_type = id_type ? id_type : '',
        this.image = image ? image : '',
        this.description = description ? description : '',
        this.public_id = public_id ? public_id : '',
        this.fullpath = fullpath ? fullpath : ''
    }
}