export default class EntityAddress {
    constructor(
        idEntity,
        idCountry,
        descCountry,
        idState,
        descState,
        idMunicipality,
        descMunicipality,
        idPostalCode,
        descPostalCode,
        codePostal,
        suburb,
        street,
        outdoorNumber,
        interiorNumber,
        coordinates,
        latitude,
        length
    ) {
        this.idEntity = idEntity ? idEntity : '',
        this.idCountry = idCountry ? idCountry : '',
        this.descCountry = descCountry ? descCountry : '',
        this.idState = idState ? idState : '',
        this.descState = descState ? descState : '',
        this.idMunicipality = idMunicipality ? idMunicipality : '',
        this.descMunicipality = descMunicipality ? descMunicipality : '',
        this.codePostal = codePostal ? codePostal : '',
        this.suburb = suburb ? suburb : '',
        this.street = street ? street : '',
        this.outdoorNumber = outdoorNumber ? outdoorNumber :  '',
        this.interiorNumber = interiorNumber ? interiorNumber : '',
        this.coordinates = coordinates ? coordinates : '',
        this.latitude = latitude ? latitude : '',
        this.length = length ? length : '',
        this.idPostalCode = idPostalCode ? idPostalCode : '',
        this.descPostalCode = descPostalCode ? descPostalCode : ''
    }
}