import ApiRebyu from '../config/ApiRebyu';
import api_url from '../../utils/api.url';

export default  {

/**
 * Función que se encarga de guardar los links compartidos de la entidad
 * @param {any} data Modelo de datos del Link
 * @returns 
 */
  async save(data) {
    try {
        const url = api_url.APP_REBYU_SHARED_LINK_SAVE;
        return await ApiRebyu.post(url, data);
    } catch (error) {
        return error.response.data;
    }
}, 

/**
 * Funcion que permite obtener los Links de la entidad por id
 * @param {*} id ID de la entidad
 * @returns 
 */
async getBYIdEntity(id) {
    try {
        const url = api_url.APP_REBYU_SHARED_LINK_GET_BY_ID_ENTITY + id;
        return await ApiRebyu.get(url);
    } catch (error) {
        return error.response.data;
    }
}, 

/**
 * Función que permite actualizar el estatus del link de la entidad
 * @param {*} id ID del link
 * @param {*} data Modelo de datos del link
 * @returns 
 */
async status(id, data) {
    try {
        const url = api_url.APP_REBYU_SHARED_LINK_STATUS + id;
        return await ApiRebyu.put(url, data);
    } catch (error) {
        return error.response.data;
    }
}, 

/**
 *  Funcion que permite obtener la entidad por codigo compartido
 * @param {*} code Código
 * @returns 
 */
async  getByCode(code, type) {
    try {
        const url = api_url.APP_REBYU_SHARED_GET_BY_CODE + code + '/' + type;
        return await ApiRebyu.get(url);
    } catch (error) {
        return error.response.data;
    }
}, 


}