<template>
  <div class="px-5 py-2">
     <v-loading :is_loading="isloading" />

     <!-- LOADER -->
     <div class="grid grid-cols-1 md:grid-cols-12 grid-flow-col gap-10 gap-y-5" v-if="loading">
      <div class="col-start-1 col-end-1 md:col-start-1 md:col-end-8">
        <v-skeleton :type="'card-avatar, list-item-two-line'" :width="'100%'" />
      </div>
      <div class="col-start-1 col-end-1 md:col-start-8 md:col-end-13">
        <v-skeleton :type="'card-avatar, list-item-two-line'" :width="'100%'" />
      </div>
     </div>

     <!-- FORMS -->
     <div v-else>


    <div class="grid gap-y-2">
      <div class="flex justify-between item-center">
        <label class="font-sans font-bold tracking-wide text-gray-500 text-2xl">{{'Datos de Ubicación'}}</label>
        <v-dialog 
          v-model="iscreate"
          persistent
          max-width="800px"
          :retain-focus="false"
           v-security="{acm: acm.ENTITYADDRESS.name, acc: acc.ADD_ADDRESS_ENTITY}"
          >
         <template  v-slot:activator="{ on, attrs }" >
          <v-btn
            v-bind="attrs" v-on="on"
              color="pink"
              dark
              fab
              small
              >
              <v-icon>mdi-plus</v-icon>
          </v-btn>
         </template>
         <v-address-entity-create :id_entity="id_entity"  :open="iscreate" v-on:close="iscreate = false" v-on:success="onsuccess" />
        </v-dialog>
       
      </div>
      <v-divider></v-divider>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-12 grid-flow-col gap-10 gap-y-5">
      <div class="text-center py-3" v-if="!address && !address.id">
        <span class="font-sans tracking-wide text-gray-400">Agrega los datos de la ubicación de tu negocio</span> 
      </div>
      <div class="grid  col-start-1 col-end-1 md:col-start-1 md:col-end-8 border border-gray-200 mt-5 shadow-sm rounded-md">
        <div class="flex justify-between item-center text-gray-500 font-bold bg-gray-100 px-5 py-1 font-sans tracking-wide rounded-tr-md rounded-tl-md border-b border-gray-200">
          <div class="h-10 flex items-center gap-2 ">
            <f-icon :icon="['fas', 'location-arrow']" />
            <small>DIRECCIÓN ACTUAL</small>
          </div>
          <div>
            <v-dialog 
              v-model="isupdate"
              persistent
              max-width="800px"
              :retain-focus="false"
              v-if="address && address.id"
              v-security="{acm: acm.ENTITYADDRESS.name, acc: acc.EDIT_ADDRESS}"
              >
            <template  v-slot:activator="{ on, attrs }" >
              <svg v-bind="attrs" v-on="on" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
            </svg>
            </template>
            <v-address-entity-update :id="address.id" :id_entity="id_entity"  :open="isupdate" v-on:close="isupdate = false" v-on:success="onupdate" />
            </v-dialog>
          </div>
        </div>
        <div class="sm:px-5 md:px-10 sm:py-1 md:py-5 grid gap-y-5">
          <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
            <div class="flex gap-5">
                <label class="text-base font-sans font-bold text-gray-700 md:text-lg">País: </label>
                <label class="text-base font-sans md:text-lg text-gray-500">{{address.descCountry ? address.descCountry : ''}}</label>
            </div>
              <div class="flex gap-5">
                <label class="text-base font-sans font-bold text-gray-700 md:text-lg">Estado: </label>
                <label class="text-base font-sans md:text-lg text-gray-500">{{address.descState ? address.descState : ''}}</label>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
            <div class="flex gap-5">
                <label class="text-base font-sans font-bold text-gray-700 md:text-lg">Municipio: </label>
                <label class="text-base font-sans md:text-lg text-gray-500">{{address.descMunicipality ? address.descMunicipality : ''}}</label>
            </div>
              <div class="flex gap-5">
                <label class="text-base font-sans font-bold text-gray-700 md:text-lg">Colonia: </label>
                <label class="text-base font-sans md:text-lg text-gray-500">{{address.suburb ? address.suburb : ''}}</label>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
            <div class="flex gap-5">
                <label class="text-base font-sans font-bold text-gray-700 md:text-lg">Calle: </label>
                <label class="text-base font-sans md:text-lg text-gray-500">{{address.street ? address.street : ''}}</label>
            </div>
              <div class="flex gap-5">
                <label class="text-base font-sans font-bold text-gray-700 md:text-lg">Número Exterior: </label>
                <label class="text-base font-sans md:text-lg text-gray-500">{{address.outdoorNumber ? address.outdoorNumber : ''}}</label>
            </div>
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 px-1 py-1 gap-5">
            <div class="flex gap-5">
                <label class="text-base font-sans font-bold text-gray-700 md:text-lg">Número Interior: </label>
                <label class="text-base font-sans md:text-lg text-gray-500">{{address.interiorNumber ? address.interiorNumber : ''}}</label>
            </div>
              <div class="flex gap-5">
                <label class="text-base font-sans font-bold text-gray-700 md:text-lg">Código Postal: </label>
                <label class="text-base font-sans md:text-lg text-gray-500">{{address.codePostal ? address.codePostal : ''}}</label>
            </div>
            </div>
         </div>
        <div class="h-10 gap-2 flex items-center text-gray-500 font-bold bg-gray-100 px-5 py-1 font-sans tracking-wide  border-b border-t border-gray-200">
            <f-icon :icon="['fas', 'map-marked']" />
          <small>UBICACIÓN EN EL MAPA </small>
        </div>
        <div>
          <div>
               <v-google-maps v-if="markers && markers.lat && markers.lng" :isclickable="false" :isdraggable="false" :vmarkers="markers ? markers : null" />
               <div class="flex justify-center" v-if="!markers || !markers.lat || !markers.lng">
                  <label class="text-lg text-center text-gray-400 font-sans tracking-wide font-semibold" >No se ah seleccionado la ubicación de tu negocio</label>
               </div>
          </div>
        </div>
      </div>
      <div class=" col-start-1 col-end-1 md:col-start-8 md:col-end-13 border border-gray-200 mt-5 shadow-sm rounded-md sm:px-5 md:px-10 sm:py-2 md:py-5">
        <div class="flex justify-start item-center h-auto text-gray-500 gap-3">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
          </svg>
          <span class="font-sans font-semibold tracking-wide">Direcciones</span>
        </div>
        <v-divider class="mt-2"></v-divider>
        <div class="mt-2">
            <v-expansion-panels
              v-for="(item,i) in directions"
              accordion
              flat
              :key="i"
              class="border border-gray-200"
            >
             <v-expansion-panel>
              <v-expansion-panel-header >
                <div class="flex justify-between items-center">
                    <div class="font-sans text-gray-500 tracking-wide">
                      <span>{{item.street ? item.street : ''}} {{item.outdoorNumber ? item.outdoorNumber : '0'}}</span>
                    </div>
                    <div>
                      <div class="w-20 text-center rounded-sm bg-pink-200 border border-pink-500" v-if="item.ultimo">
                          <small class="text-fucsia-lighter font-bold font-sans tracking-wide">ACTUAL</small>
                      </div>
                    </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-address-sm-card :item="item" />
                <div class="flex justify-end">
                  <button v-if="!item.ultimo"  v-security="{acm: acm.ENTITYADDRESS.name, acc: acc.CHANGED_ADDRESS}"
                      class="bg-fucsia-lighter text-white text-sm font-bold font-sans tracking-wide mr-2 px-3 rounded-md shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                  @click="changeDirection(item.id)"  >Cambiar</button>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </div>
      </div>
    </div>

     </div>
  </div>
</template>

<script>
import SecurityMixin from '@/mixins/security/RBSecurityMixin';
import toastMixin from "@/mixins/vue/toastmixin";
import globalMixin from "@/mixins/globalMixin";
import vaddresscreate from './address/create.vue';
import vaddressupdate from './address/update.vue';
import addressEntityService from '../../services/entity/addressEntity.service';
import AddressEntity from '../../models/entity/entity.model';
import vaddresssm from './address/components/vaddress.sm.vue';


export default {
  mixins: [toastMixin, globalMixin, SecurityMixin],
  props: {
    id_entity: {
      type: String,
      default: ""
    }
  },
  components: {
    'v-address-entity-create': vaddresscreate,
    'v-address-entity-update': vaddressupdate,
    'v-address-sm-card': vaddresssm
  },
  data: function() {
    return {
      iscreate: false,
      isupdate: false,
      isloading: false,
      loading: false,
      address: new AddressEntity(),
      directions: [],
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init: async function() {
      this.loading = true;
      await this.getAddressEntity(this.id_entity);
      await this.getAllAddressEntity(this.id_entity);
       this.loading = false;
    },
    getAddressEntity: async function(id) {
      let response = await addressEntityService.getByIdEntity(id);
      if(response.success) {
        this.address = response.data;
      }else {
        this.failure(response.message);
      }
    },
    getAllAddressEntity: async function(id) {
      let response = await addressEntityService.getAllByIdEntity(id);
      if(response.success) {
        this.directions = response.data;
      }else {
        this.failure(response.message);
      }
    },
    onsuccess: function() {
       this.iscreate = false;
       this.init();
    },
    onupdate: function() {
       this.isupdate = false;
       this.init();
    },
    changeDirection: async function(id) {
      let confirm = await this.confirm("Actualizar Dirección", "¿Desea que la dirección actual sea el registro seleccionado?");
      if(confirm) {
        this.isloading = true;
        let data = {idEntity: this.id_entity};
        let response = await addressEntityService.addressSet(id, data);
        this.isloading = false;
        if(response.success) {
          this.success(response.message);
          this.init();
        }else {
          this.failure(response.message);
        }
      }
    }
  },
  computed: {
     markers: function() {
            return {
                lat: this.address.latitude ? parseFloat(this.address.latitude) : "",
                lng: this.address.length ? parseFloat(this.address.length) : ""
            }
      }
  }

}
</script>

<style>

</style>