export default class EntityProfile {
    constructor(
        url,
        idEntity,
        public_id,
        fullpath
    ) {
        this.url = url ? url : '',
        this.idEntity = idEntity ? idEntity : '',
        this.public_id = public_id ? public_id : '',
        this.fullpath = fullpath ? fullpath : ''
    }
}