import { HProducts } from "../../../config/axios.context";
import { APP_PRODUCTS_SCHEDULE_SAVE, 
         APP_PRODUCTS_SCHEDULE_UPDATE, 
         APP_PRODUCTS_SCHEDULE_GET_BY_CODE,
         APP_PRODUCTS_SCHEDULE_GET_BY_ENTITY, 
         APP_GETCSCHEDULE_GET_BY_CODE, 
         APP_PRODUCTS_SCHEDULE_SAVE_TAG,
         APP_UPDATE_ACTIVE_CSCHEDULE
        } 
from '../../../utils/apiurl';

export default  {

    /**
     * Función que permite guardar los datos del horario
     * @param {*} data Modelom de datos de horario
     * @returns 
     */
     async saveTag(data) {
        try {
            const url = APP_PRODUCTS_SCHEDULE_SAVE_TAG;
            return await HProducts.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },


    /**
     * Función que permite establecer el horario activo
     * @param {*} data Modelo de datos de horario
     * @returns 
     */
     async updateActiveSchedule(data) {
        try {
            const url = APP_UPDATE_ACTIVE_CSCHEDULE;
            return await HProducts.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite guardar los datos del horario
     * @param {*} data Modelom de datos de horario
     * @returns 
     */
    async save(data) {
        try {
            const url = APP_PRODUCTS_SCHEDULE_SAVE;
            return await HProducts.post(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

   /**
    * Función que permite actualizar los datos del horario
    * @param {*} id ID de la entidad
    * @param {*} data Modelo de datos de horario
    * @returns 
    */
    async update(id, data) {
        try {
            const url = APP_PRODUCTS_SCHEDULE_UPDATE + id;
            return await HProducts.put(url, data);
        } catch (error) {
            return error.response.data;
        }
    },

    /**
     * Función que permite obtener los datos del horario de la entidad
     * @param {*} id ID de la entidad
     * @param {*} code Tipo de horario
     * @returns 
     */
    async getByCode(id, code) {
        try {
            const url = APP_PRODUCTS_SCHEDULE_GET_BY_CODE + id + '/' + code;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    async getByEntity(id,code='SYST_SCHEDULE_ATTENTION',all=0) {
        try {
            const url = APP_PRODUCTS_SCHEDULE_GET_BY_ENTITY + id + '/' + code + '/' + all;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    async getCSchedule(id) {
        try {
            const url = APP_GETCSCHEDULE_GET_BY_CODE + id;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

    async getDaysAll(id) {
        try {
            const url = APP_PRODUCTS_SCHEDULE_GET_BY_ENTITY + id;
            return await HProducts.get(url);
        } catch (error) {
            return error.response.data;
        }
    },

}