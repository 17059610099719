import ApiProducts from '../config/ApiProducts';
import api_url from '../../utils/api.url';

export default  {

/**
 * Función que se encarga de guardar los datos de las categorias
 * @param {any} data Modelo de datos de la categoria
 * @returns 
 */
 async save(data) {
    try {
        const url = api_url.APP_PRODUCTS_CATEGORY_SAVE;
        return await ApiProducts.post(url, data);
    } catch (error) {
        return error.response.data;
    }
}, 

/**
 * Función  que permite obtener los datos de la categoria
 * @param {*} id ID de la categoria
 * @param {*} id_entity Id de la entidad
 * @returns 
 */
 async getById(id, id_entity) {
    try {
        const url = api_url.APP_PRODUCTS_CATEGORY_GET_BY_ID + id + '/' + id_entity;
        return await ApiProducts.get(url);
    } catch (error) {
        return error.response.data;
    }
}, 

/**
 * Función que permite actualizar el estatus de la categoria
 * @param {*} id ID de la categoria
 * @param {*} data Modelo de datos de la categoria
 * @returns 
 */
 async status(id, data) {
    try {
        const url = api_url.APP_PRODUCTS_CATEGORY_STATUS + id;
        return await ApiProducts.put(url, data);
    } catch (error) {
        return error.response.data;
    }
}, 

/**
 * Función que permite actualizar los datos de la categoría
 * @param {*} id ID de la categoria
 * @param {*} data Modelo de datos de la categoría
 * @returns 
 */
async update(id, data) {
    try {
        const url = api_url.APP_PRODUCTS_CATEGORY_UPDATE + id;
        return await ApiProducts.put(url, data);
    } catch (error) {
        return error.response.data;
    }
}, 

}