<template>
  <v-card>
       <v-loading :is_loading="isloading" />
       <v-modal-title v-on:close="$emit('close')">
            Nuevo Horario
       </v-modal-title>
       <div class="py-2 sm:px-0 md:px-5">
           <div class="grid grid-cols-1 gap-y-2 gap-10 px-3 md:px-5 py-2">
            <div>
                    <label class="text-base">Nombre</label>
                    <input :disabled="isloading" v-model="$v.model.name.$model" class="input-full-fucsia"  placeholder="Ingrese el nombre" />
                    <small class="text-error" v-show="$v.model.name.$anyDirty && !$v.model.name.required">* El nombre es requerido</small>
            </div>
           </div>
           <div class="grid grid-cols-1 gap-y-2 gap-10 px-3 md:px-5 py-2">
                <div>

                            <label class="text-base">Tipo</label>
                            <v-autocomplete
                                v-model="model.id_schedule_type"
                                :items="[ { text : 'ATENCIÓN', value : 'SYST_SCHEDULE_ATTENTION' }, { text : 'RESERVACIÓN', value : 'SYST_SCHEDULE_BOOKING' } ]"
                                dense
                                filled
                                label=""
                            ></v-autocomplete>
                </div>
           </div>

       </div>
        <v-card-actions >
          <v-spacer class="hidden md:block" ></v-spacer>
          <div class="grid w-full md:w-min gap-y-2 gap-2 md:flex">
            <div class="w-full">
                <button
                    class="w-full bg-gray-200 text-gray-500 text-lg tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-gray-500 hover:text-white focus:outline-none" 
                    @click="$emit('close')">Cancelar</button>
            </div>
            <div class="w-full">
                <button
                    class="w-full bg-fucsia-lighter text-white text-lg tracking-wide mr-2 sm:px-5 md:px-3  sm:py-2 md:py-1 rounded-lg shadow-sm hover:bg-fucsia-dark focus:outline-none" 
                @click="sendTag" >Guardar</button>
            </div>
          </div>
        </v-card-actions>
  </v-card>
</template>

<script>
import toastMixin from "@/mixins/vue/toastmixin";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import scheduleService from '../../../../services/HSuite/HProduct/schedule/schedule.service';
import uuid from 'uuid';

export default {
    mixins: [toastMixin, validationMixin],
    props: {
        id_entity: {
            type: String,
            default: ""
        }
    },
    data: function() {
        return {
            isloading: false,
            name:"",
            model:{
                name : "",
                id_entity : null,
                id_schedule_type : "SYST_SCHEDULE_ATTENTION"
            }
        }
    },
    created(){
        this.name = "";
        this.model.id_entity = this.id_entity;
    },

    validations: {
            model:{
                name: {
                    required
                }
            }
    },
    methods: {
        
            async sendTag(){

                try {
                    
                    const id = uuid.v4().toString();
                    console.log(id);
                    this.$v.$touch();
                    if(!this.$v.$anyError) {
                        let response = await scheduleService.saveTag( this.model );
                        if(response.success){
                            this.success(response.message);
                            this.$emit('success');
                        }
                        else {
                            this.failure(response.message);
                        }
                    }

                } catch (error) {
                    console.log(error);
                }
            }


    },
    watch: {
        
    }

}
</script>

<style scoped>
.cropper {
	height: 300px;
	background: #DDD;
}
</style>